import React from 'react';
import DataTable from '../../../data-table';
import {
  communicationMethodSelector,
  consultationIdSelector,
  patientInformationSelector,
  requestDateSelector,
  requestReasonSelector,
  scheduledVisitsTableActionsSelector,
  timeRemainingSelector
} from '../selectors';
import { getConsultationAlertRowStyle } from '../utils';

const COLUMNS = [
  {
    name: '',
    selector: communicationMethodSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.scheduled_visits_table.patient'),
    selector: patientInformationSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.scheduled_visits_table.reason_for_visit'),
    selector: requestReasonSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.scheduled_visits_table.visit_id'),
    selector: consultationIdSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.scheduled_visits_table.remaining_time'),
    selector: timeRemainingSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.scheduled_visits_table.date_and_time'),
    selector: requestDateSelector
  },
  {
    name: I18n.t('dashboard.consult_queues.scheduled_visits_table.actions.title'),
    selector: scheduledVisitsTableActionsSelector,
    width: '130px'
  }
];

const ScheduledVisitsTable = ({ data }) => (
  <DataTable columns={COLUMNS} data={data} rowStyles={getConsultationAlertRowStyle} />
);

export default ScheduledVisitsTable;
