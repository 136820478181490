import React from 'react';
import PropTypes from 'prop-types';
import ChiefComplaint from './chief-complaint/chief-complaint-container';
import DurationSeverityProgression from './duration-severity-progression/duration-severity-progression-container';
import Symptoms from './symptoms/symptoms-container';
import SystemGeneratedNote from './system-generated-note/system-generated-note-container';
import AdditionalNotes from './additional-notes/additional-notes-container';
import ICachedComplaint from '../interfaces/cached-complaint-interface';
import ClinicalAlerts from 'app/clinical-alerts/components/clinical-alerts-container';
import '../styles.scss';

const SubjectiveTab = ({
  requestReason,
  patientGender,
  patientAge,
  specialty,
  isComplaintSelected,
  cachedComplaint,
  cachedClinicalAlerts
}) => (
  <div id="subjective-tab">
    <h2>Subjective</h2>
    <h4>Patient-Provided Reason for Visit</h4>
    <p>{requestReason}</p>
    <ChiefComplaint specialty={specialty} cachedComplaint={cachedComplaint} />
    <div id="complaint-information" style={{ display: isComplaintSelected ? 'block' : 'none' }}>
      <DurationSeverityProgression />
      <Symptoms />
      <ClinicalAlerts cachedClinicalAlerts={cachedClinicalAlerts} />
      <SystemGeneratedNote patientGender={patientGender} patientAge={patientAge} />
    </div>
    <AdditionalNotes />
  </div>
);

SubjectiveTab.propTypes = {
  requestReason:        PropTypes.string,
  patientGender:        PropTypes.string,
  patientAge:           PropTypes.number,
  specialty:            PropTypes.string.isRequired,
  isComplaintSelected:  PropTypes.bool,
  cachedComplaint:      ICachedComplaint,
  cachedClinicalAlerts: PropTypes.array
};

export default SubjectiveTab;
