import React from 'react';
import PropTypes from 'prop-types';

const SendMessageAction = ({ consultationId, messageRoomId, scheduledDate, memberGroupAllowed }) => {
  const newMessagingToggleOn = window.FEATURE_TOGGLES.new_messages_architecture;

  const canSendMessage = scheduledDate && moment(scheduledDate).subtract(24, 'hours') < moment();
  const supportsNewMessaging = newMessagingToggleOn && (messageRoomId || memberGroupAllowed);

  if (!(canSendMessage && supportsNewMessaging)) return null;

  return (
    <a href={`/care_team_messages/${messageRoomId}?autolock=true&consultation_id=${consultationId}`}>
      {I18n.t('dashboard.consult_queues.scheduled_visits_table.actions.send_message')}
    </a>
  );
};

SendMessageAction.propTypes = {
  consultationId: PropTypes.number.isRequired,
  messageRoomId: PropTypes.number.isRequired
};

export default SendMessageAction;
