import store from 'app/lib/store';
import validation from 'app/subjective-tab/utilities/constants/validation';
import { clinicalAlertValidator } from 'app/consult-navigation/validators/clinical-alert-validator';
import { keys, chain } from 'lodash';

const complaints = () => {
  const { duration } = validation;
  const state = store.getState();
  const durationSeverityProgressionFormValues = state.durationSeverityProgressionForm.formValues;

  const durationValidator = {
    durationValue: {
      invalid:
        !durationSeverityProgressionFormValues.duration_value ||
        durationSeverityProgressionFormValues.duration_value <= duration.minValue ||
        durationSeverityProgressionFormValues.duration_value > duration.maxValue,
      message:  'You must enter duration number.',
      isInline: true
    },
    durationType: {
      invalid:  !durationSeverityProgressionFormValues.duration_cd,
      message:  'You must select duration period.',
      isInline: true
    }
  };

  const severityValidator = {
    severity: {
      invalid:  !durationSeverityProgressionFormValues.severity_cd,
      message:  'You must select severity.',
      isInline: true
    },
    otherSeverity: {
      invalid:
        durationSeverityProgressionFormValues.severity_cd === 'CLINICALSEVERITY_OTHER' &&
        !durationSeverityProgressionFormValues.severity_value,
      message:  "You've chosen 'Other' as a severity, please specify what the severity is.",
      isInline: true
    }
  };

  const progressionValidator = {
    progression: {
      invalid:  !durationSeverityProgressionFormValues.progression_cd,
      message:  'You must select progression.',
      isInline: true
    },
    otherProgression: {
      invalid:
        durationSeverityProgressionFormValues.progression_cd === 'CLINICALPROGRESSION_OTHER' &&
        !durationSeverityProgressionFormValues.progression_value,
      message:  "You've chosen 'Other' as a progression, please specify what the progression is.",
      isInline: true
    }
  };

  const validators = {
    chiefComplaint: {
      invalid: state.chiefComplaint.complaintId === null,
      message: 'You must choose a complaint.'
    },
    otherChiefComplaint: {
      invalid:  state.chiefComplaint.complaintText === 'Other' && !state.chiefComplaint.other,
      message:  "You've chosen 'Other' as a complaint, please specify what the complaint is.",
      isInline: true
    },
    ...(state.chiefComplaint.durationRequired && durationValidator),
    ...(state.chiefComplaint.severityRequired && severityValidator),
    ...(state.chiefComplaint.progressionRequired && progressionValidator),
    ...(state.clinicalAlertsActivatedFlag && clinicalAlertValidator(state)),
    symptoms: {
      invalid:  !keys(state.symptomsForm.radioValues).length,
      message:  'You must select at least one symptom.',
      isInline: true
    }
  };

  let tabErrorMessages = [];

  // TODO Investigate why we have these special cases for validation
  if (validators.chiefComplaint.invalid) {
    tabErrorMessages.push({
      validator: {
        message: validators.chiefComplaint.message,
        invalid: true
      },
      validatorKey: 'chiefComplaint'
    });
  } else if (validators.otherChiefComplaint.invalid) {
    tabErrorMessages.push({
      validator: {
        message: validators.otherChiefComplaint.message,
        invalid: true
      },
      validatorKey: 'otherChiefComplaint'
    });
  } else {
    const validatorsKeys = Object.keys(validators);

    tabErrorMessages = chain(validators)
      .values()
      .map((validator, validatorIndex) => ({
        validator,
        validatorKey: validatorsKeys[validatorIndex]
      }))
      .value();
  }

  const invalidValidations = tabErrorMessages.filter(({ validator }) => validator.invalid);

  if (!invalidValidations.length) {
    const { complaintText, other } = state.chiefComplaint;

    const chiefComplaintText = complaintText === 'Other' ? other : complaintText;
    window.updateNewSTabSummary(chiefComplaintText);
  }

  return tabErrorMessages;
};

export default () => {
  // Legacy complaint validation
  const { complaintsVerified } = window.consultationInterview.validation;

  const that = window.consultationInterview.validation;
  const isSubjectiveFeatureToggleOn = Boolean($('#react_subjective_tab_container').length);

  return isSubjectiveFeatureToggleOn ? Promise.resolve(complaints()) : Promise.resolve(complaintsVerified.apply(that));
};
