import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SubjectiveTab from './subjective-tab';
import {
  durationSeverityProgressionForm,
  additionalNotesForm,
  symptomsFormInitialize,
  addedSymptomsInitialize
} from '../actions';

class SubjectiveTabContainer extends Component {
  componentDidMount() {
    const {
      subjectiveDocumentation: {
        duration_cd,
        duration_value,
        severity_cd,
        severity_value,
        progression_cd,
        progression_value,
        subjective_additional_notes,
        symptomsFormReducer,
        symptomsAddedReducer
      }
    } = this.props;

    this.props.initializeDurationSeverityProgressionForm({
      duration_cd,
      duration_value,
      severity_cd,
      severity_value,
      progression_cd,
      progression_value
    });

    this.props.initializeAdditionalNotesForm({
      subjective_additional_notes
    });

    if (symptomsFormReducer) {
      this.props.symptomsFormInitialize(JSON.parse(symptomsFormReducer));
    }

    if (symptomsAddedReducer) {
      this.props.addedSymptomsInitialize(JSON.parse(symptomsAddedReducer));
    }
  }

  render() {
    const {
      subjectiveDocumentation: {
        patientAge,
        patientGender,
        requestReason,
        specialty,
        complaintId,
        complaintText,
        other,
        noteFormat,
        durationRequired,
        severityRequired,
        progressionRequired,
        clinicalAlerts
      },
      isComplaintSelected
    } = this.props;

    const cachedComplaint = complaintId
      ? {
        complaintId: +complaintId,
        complaintText,
        other,
        noteFormat,
        durationRequired,
        severityRequired,
        progressionRequired
      }
      : null;

    return (
      <SubjectiveTab
        isComplaintSelected={isComplaintSelected}
        patientAge={+patientAge}
        patientGender={patientGender}
        requestReason={requestReason}
        specialty={specialty}
        cachedComplaint={cachedComplaint}
        cachedClinicalAlerts={clinicalAlerts}
      />
    );
  }
}

const mapStateToProps = state => ({
  isComplaintSelected: state.chiefComplaint.isComplaintSelected
});

const mapDispatchToProps = {
  initializeDurationSeverityProgressionForm: durationSeverityProgressionForm.initialize,
  initializeAdditionalNotesForm:             additionalNotesForm.initialize,
  symptomsFormInitialize,
  addedSymptomsInitialize
};

SubjectiveTabContainer.propTypes = {
  isComplaintSelected:     PropTypes.bool,
  subjectiveDocumentation: PropTypes.shape({
    ref_class_complaint_id: PropTypes.string,
    duration_cd:            PropTypes.string,
    duration_value:         PropTypes.string,
    severity_cd:            PropTypes.string,
    severity_value:         PropTypes.string,
    progression_cd:         PropTypes.string,
    progression_value:      PropTypes.string,
    symptomsFormReducer:    PropTypes.string,
    symptomsAddedReducer:   PropTypes.string,
    complaintId:            PropTypes.string,
    complaintText:          PropTypes.string,
    other:                  PropTypes.string,
    requestReason:          PropTypes.string,
    patientGender:          PropTypes.string,
    patientAge:             PropTypes.string,
    specialty:              PropTypes.string,
    noteFormat:             PropTypes.string,
    durationRequired:       PropTypes.bool,
    severityRequired:       PropTypes.bool,
    progressionRequired:    PropTypes.bool
  }),
  initializeDurationSeverityProgressionForm: PropTypes.func.isRequired,
  initializeAdditionalNotesForm:             PropTypes.func.isRequired,
  symptomsFormInitialize:                    PropTypes.func.isRequired,
  addedSymptomsInitialize:                   PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SubjectiveTabContainer);
