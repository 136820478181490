import cacheInterview from './cache-interview';
import { switchMap, debounceTime } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { UPDATE_SCRATCHPAD_TEXT } from '../scratchpad/actionTypes';
import {
  CONFIRM_PENDING_PRESCRIPTIONS_LIST,
  UPSERT_TO_PENDING_PRESCRIPTION_LIST,
  UPDATE_TO_PENDING_PRESCRIPTION_LIST,
  REMOVE_FROM_PENDING_PRESCRIPTION_LIST,
  CLEAR_PENDING_PRESCRIPTION_LIST
} from '../Prescription/actionTypes';

export const cacheInterviewEpic = action$ =>
  action$.pipe(
    ofType(
      UPDATE_SCRATCHPAD_TEXT,
      UPSERT_TO_PENDING_PRESCRIPTION_LIST,
      UPDATE_TO_PENDING_PRESCRIPTION_LIST,
      REMOVE_FROM_PENDING_PRESCRIPTION_LIST,
      CLEAR_PENDING_PRESCRIPTION_LIST,
      CONFIRM_PENDING_PRESCRIPTIONS_LIST
    ),
    debounceTime(300),
    switchMap(() => {
      cacheInterview();

      return [];
    })
  );
