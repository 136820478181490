import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MainInquiry } from '.';
import Survey, { surveyInsert } from 'app/surveys';
import styles from 'app/clinical-alerts/styles/styles.scss';

const SURVEY_NAMING_KEY = 'interview[clinical_alerts][]';

export const ClinicalAlertsForm = ({
  mainInquiry,
  caFormMainInquiryChange,
  medicalAlertCodesData,
  validationErrors,
  cachedClinicalAlerts
}) => {
  const toggleDateField = (answerVal, answerCode) => ({
    SURVEYMNEMONIC_ALERTCOVID19V2: {
      SECTIONMNEMONIC_ALERTCOVID19V2: {
        Q_1_2: {
          displayAttrs: {
            visible: answerVal[answerCode] === true
          }
        }
      }
    }
  });
  
  const togglePaxlovidField = (answerVal, answerCode) => {
    return {
      SURVEYMNEMONIC_ALERTCOVID19V2: {
        SECTIONMNEMONIC_ALERTCOVID19V2: {
          Q_1_6: {
            displayAttrs: {
              visible: answerVal[answerCode] === true
            }
          }
        }
      }
    }
  };
  
  const epidemiologicalAssesmentValidationError = validationErrors.find(
    ({ validatorKey }) => validatorKey === 'alertFlgPresence'
  );

  return (
    <div>
      <h3>Epidemiological Risk Assessment</h3>

      {medicalAlertCodesData.map(medicalAlertCode => {
        surveyInsert(
          medicalAlertCode.survey,
          ['SECTIONMNEMONIC_ALERTCOVID19V2', 'Q_1_1', 'CDCSYMPTOM'],
          'callback',
          toggleDateField
        );
        
        try {
          surveyInsert(
            medicalAlertCode.survey,
            ['SECTIONMNEMONIC_ALERTCOVID19V2', 'Q_1_5', 'YES'],
            'callback',
            togglePaxlovidField
          );
        }
        catch(ex) {
          console.error('Q_1_5 not found');
        }
  
        const cachedAnswers =
          cachedClinicalAlerts &&
          cachedClinicalAlerts.find(({ table }) => table.alert_code === medicalAlertCode.alertCode);

        const cachedMainInquiry = _.get(cachedAnswers, 'table.response_flag');

        return (
          <div key={medicalAlertCode.alertCode}>
            <MainInquiry
              medicalAlertCode={medicalAlertCode}
              mainInquiry={mainInquiry || cachedMainInquiry}
              caFormMainInquiryChange={caFormMainInquiryChange}
              surveyNamingKey={SURVEY_NAMING_KEY}
              cachedClinicalAlerts={cachedAnswers || {}}
            />
            <br />
            {(mainInquiry || cachedMainInquiry) === 'Y' ? (
              <Survey
                surveyObject={medicalAlertCode.survey}
                surveyNamingKey={SURVEY_NAMING_KEY}
                cachedAnswers={cachedAnswers || {}}
                validationErrors={validationErrors}
                styles={styles}
              />
            ) : null}
            {/* This adds `alert_code` and v2_clinical_alert as top level params to a clinical_alerts hash */}
            <input type="hidden" value={medicalAlertCode.alertCode} name={`${SURVEY_NAMING_KEY}[alert_code]`} />
            <input type="hidden" value name={`${SURVEY_NAMING_KEY}[v2_clinical_alert]`} />
          </div>
        );
      })}

      {epidemiologicalAssesmentValidationError && (
        <div className="interview_inline_validation_error show">{epidemiologicalAssesmentValidationError.message}</div>
      )}
    </div>
  );
};

ClinicalAlertsForm.propTypes = {
  mainInquiry:             PropTypes.string.isRequired,
  caFormMainInquiryChange: PropTypes.func.isRequired,
  medicalAlertCodesData:   PropTypes.array,
  validationErrors:        PropTypes.array,
  cachedClinicalAlerts:    PropTypes.array
};
