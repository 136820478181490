import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { map, has, get, flattenDeep } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/client';
import { translate } from '@td/shared_utils';

// Queries / Mutations
import PROVIDER_SCHEDULES_QUERY from '../queries/provider-profile-schedules.graphql';
import CREATE_SHIFT_EVENT from '../mutations/create-provider-schedule-event.graphql';
import UPDATE_SHIFT_EVENT from '../mutations/update-provider-schedule-event.graphql';
import DELETE_SHIFT_EVENT from '../mutations/delete-provider-schedule-event.graphql';

// Components, constants and helpers
import { MyScheduleContent, AvailabilitySettings, PersonalEventModal } from '../index';
import Notification from '../components/notification';
import { scheduleTypes, MyScheduleViews, MyScheduleModals, DAY_NAMES } from '../constants';
import * as stateInit from '../../calendar/services/state-initialization';
import { separateDatesAndTimes } from '../helpers';
import saveShiftV2 from '../services/save-shift-v2';
import { eventTypeCodes, workHourTypeCodes } from '../../calendar/constants';
import { useRecommendedWorkingHours } from '../../recommended-working-hours/contexts/recommended-working-hours-context';
import { formatSingleEvent } from '../../calendar/services/get-formatted-events-v2';

const TRANSLATION_SCOPE = 'my_schedule';

const DEFAULT_PROVIDER_SHIFTS = {
  loading:   null,
  error:     null,
  schedules: {
    formattedEvents:           [],
    allATBEventsByDay:         {},
    allDayPersonalEvents:      [],
    schedulesByType:           {},
    sortedRegularWorkingHours: {}
  }
};

const MyScheduleContainer = ({ providerId, apolloClient, canEditSchedule = true }) => {
  /*
   * States
   */
  const [currentView, setCurrentView] = useState(window.location.hash === '#active=regular_working_hours' ? MyScheduleViews.SETTINGS : MyScheduleViews.DEFAULT);
  const [isRegularWorkingHoursInitiated, setIsRegularWorkingHoursInitiated] = useState(window.location.hash === '#active=regular_working_hours');
  const [activeModal, setActiveModal] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [notification, setNotification] = useState(null);
  const [validateProps, setValidateProps] = useState(stateInit.validationProps);
  const [validatePropsMessages, setValidatePropsMessages] = useState(stateInit.validationPropsMessages);
  const [providerShifts, setProviderShifts] = useState(DEFAULT_PROVIDER_SHIFTS);

  const {
    recommendedWorkingHours,
    refreshRecommendedWorkingHours,
    recommendedWorkingHoursFlag
  } = useRecommendedWorkingHours();

  /*
   * Constants
   */
  const initialWorkingHours = {
    startDateTime: moment()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0),
    endDateTime: moment()
      .set('hour', 17)
      .set('minute', 0)
      .set('second', 0)
  };

  const isActiveRegularWorkingHour = ({ eventTypeCode, recurrenceRuleEndDt }) =>
    eventTypeCode === scheduleTypes.PROVSCHEDEVENT_RH.eventTypeCode &&
    (!recurrenceRuleEndDt || moment(recurrenceRuleEndDt).isAfter(Date.now()));

  const isAcceptedBlock = ({ eventTypeCode = '' }) =>
    [
      scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode,
      scheduleTypes.PROVSCHEDEVENT_RH.eventTypeCode,
      scheduleTypes.PROVSCHEDEVENT_AWM.eventTypeCode,
      scheduleTypes.PROVSCHEDEVENT_ATB.eventTypeCode
    ].includes(eventTypeCode);

  const [customBuffer, setCustomBuffer] = useState(false);
  const isAllDayPersonalEvent = ({ allDayEventFlg, eventTypeCode }) =>
    eventTypeCode === scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode && allDayEventFlg;

  const getSchedulesByType = eventTypeCode => providerShifts.schedules.schedulesByType[eventTypeCode] || [];

  /*
   * Queries / mutations
   */
  const [fetchEvents, { loading, error, data, refetch: refetchEvents }] = useLazyQuery(PROVIDER_SCHEDULES_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      const { appointmentTimeBlockRules } = response.providerProfile;
      const appointmentTimeBlockRulesParsed = JSON.parse(appointmentTimeBlockRules);

      setValidateProps({
        minShiftLengthSize:       appointmentTimeBlockRulesParsed.min_atb_length_size,
        maxShiftLengthSize:       appointmentTimeBlockRulesParsed.max_atb_length_size,
        shiftTimeStep:            appointmentTimeBlockRulesParsed.atb_minutes_time_step,
        shiftPreselectedDuration: appointmentTimeBlockRulesParsed.atb_minutes_preselected_duration,
        minStartDate:             appointmentTimeBlockRulesParsed.atb_minutes_time_buffer
      });

      setValidatePropsMessages({
        startDateInPast:        translate(null, 'provider_calendar.validation', 'start_date_in_past'),
        endDateBeforeStartDate: translate(null, 'provider_calendar.validation', 'end_date_before_start_date'),
        endTimeBeforeStartTime: translate(null, 'provider_calendar.validation', 'end_time_before_start_time'),
        minShiftLength:         translate(null, 'provider_calendar.validation', 'min_shift_length', {
          min_atb_length_size: appointmentTimeBlockRulesParsed.min_atb_length_size
        }),
        maxShiftLength: translate(null, 'provider_calendar.validation', 'max_shift_length', {
          max_atb_length_size: appointmentTimeBlockRulesParsed.max_atb_length_size / 60
        }),
        overlappingShift: translate(null, 'provider_calendar.validation', 'overlapping_shift'),
        minBufferTime:    translate(null, 'provider_calendar.validation', 'atb_minutes_time_buffer', {
          atb_minutes_time_buffer: appointmentTimeBlockRulesParsed.atb_minutes_time_buffer / 60
        })
      });
    }
  });

  const loadSchedules = useCallback(
    ({ startDate, endDate }) => fetchEvents({
      variables: {
        providerId,
        datetimeRange: {
          startDate,
          endDate
        }
      }
    }), [fetchEvents]);

  const handleMutationError = mutationError => {
    const defaultErrorMsg = translate(null, TRANSLATION_SCOPE, 'mutations.error');
    const errorMsg = get(flattenDeep([mutationError]), 0, defaultErrorMsg);
    setNotification({ type: 'error', message: typeof errorMsg === 'string' || errorMsg instanceof String ? errorMsg : defaultErrorMsg });
  };

  const handleMutationSuccess = (mutationType, warnings = []) => {
    const successType = mutationType ? `${mutationType}_success` : 'success';
    setNotification({
      type:    warnings.length === 0 ? 'success' : 'warning',
      message: translate(null, TRANSLATION_SCOPE, `mutations.${successType}`),
      warnings
    });
    refetchEvents();
    refreshRecommendedWorkingHours();
  };

  const [callCreateShift, { loading: createShiftLoading }] = useMutation(CREATE_SHIFT_EVENT, {
    onCompleted: ({ tasCreateProviderScheduleEvent: { success, errors, warnings } }) => {
      if (success) {
        handleMutationSuccess('create', warnings);
      } else {
        handleMutationError(errors);
      }
    },
    onError: mutationError => handleMutationError(mutationError)
  });

  const [callUpdateShift, { loading: updateShiftLoading }] = useMutation(UPDATE_SHIFT_EVENT, {
    onCompleted: ({ tasUpdateProviderScheduleEvent: { success, errors, warnings } }) => {
      if (success) {
        handleMutationSuccess('update', warnings);
      } else {
        handleMutationError(errors);
      }
    },
    onError: mutationError => handleMutationError(mutationError)
  });

  const [callDeleteShift, { loading: deleteShiftLoading }] = useMutation(DELETE_SHIFT_EVENT, {
    onCompleted: ({ tasDeleteProviderScheduleEvent: { success, errors } }) => {
      if (success) {
        handleMutationSuccess('delete');
      } else {
        handleMutationError(errors);
      }
    },
    onError: mutationError => handleMutationError(mutationError)
  });

  /*
   * Callbacks
   */
  const resetNotifications = () => {
    setNotification(null);
  };

  const handleOpenSettings = () => {
    setCurrentView(MyScheduleViews.SETTINGS);
  };

  const handleGoBack = () => {
    setCurrentView(MyScheduleViews.DEFAULT);
    resetNotifications();
  };

  const handleAddEditEvent = event => {
    if (!event || event.typeCode === scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode) {
      setActiveModal({
        type: MyScheduleModals.PERSONAL_EVENTS,
        event
      });
    }
    resetNotifications();
  };

  const resetModalSubmit = () => {
    setActiveModal(null);
    setSubmitting(false);
    resetNotifications();
  };

  const handleSubmit = ({ type, action, event, submitData }) => {
    if (loading || createShiftLoading || updateShiftLoading || deleteShiftLoading) return;
    resetModalSubmit();

    if (!type || !(type.id === MyScheduleModals.REGULAR_WORKING_HOURS.id && action !== 'delete' ? submitData : event)) {
      return setNotification({ type: 'error', message: translate(null, TRANSLATION_SCOPE, 'mutations.error') });
    }

    if (type.id === MyScheduleModals.REGULAR_WORKING_HOURS.id) {
      if (action === 'delete') {
        map(event, ({ providerScheduleId }) => {
          if (providerScheduleId) {
            callDeleteShift({ variables: { provider_schedule_id: providerScheduleId } });
          } else {
            setNotification({ type: 'error', message: translate(null, TRANSLATION_SCOPE, 'mutations.error') });
          }
        });
      } else {
        if (!has(submitData, 'success') || !has(submitData, 'error') || !has(submitData, 'errorList')) {
          return setNotification({ type: 'error', message: translate(null, TRANSLATION_SCOPE, 'mutations.error') });
        }

        const { success, error: submitError, errorList } = submitData;

        const submitNotification = {
          type:    success && !submitError ? 'success' : 'error',
          message: success && !submitError ? translate(null, TRANSLATION_SCOPE, 'mutations.success') : errorList[0]
        };

        setNotification(submitNotification);
        refetchEvents();
        refreshRecommendedWorkingHours();
      }

      return;
    }

    if (action === 'delete') {
      const { providerScheduleId } = event;

      if (providerScheduleId) {
        callDeleteShift({ variables: { provider_schedule_id: providerScheduleId } });
      } else {
        setNotification({ type: 'error', message: translate(null, TRANSLATION_SCOPE, 'mutations.error') });
      }
    } else {
      saveShiftV2({
        formState:            separateDatesAndTimes(event),
        selectedEvent:        event,
        validateProps,
        validatePropsMessages,
        setInterfaceError:    handleMutationError,
        callCreateShift,
        callUpdateShift,
        isProviderCalendarV2: true,
        setModalErrorMessage: () => {}
      });
    }
  };

  const handleSavePersonalEvent = saveEventData => {
    setSubmitting(true);
    handleSubmit({ ...activeModal, event: { ...activeModal.event, ...saveEventData } });
  };

  const handleDeletePersonalEvent = () => {
    setSubmitting(true);
    handleSubmit({ ...activeModal, action: 'delete', event: { ...activeModal.event } });
  };

  const handleResetInit = () => {
    setIsRegularWorkingHoursInitiated(false);
  };

  /*
   * Effects
   */
  useEffect(() => {
    let timeout;
    if (notification !== null && notification.message !== translate(null, TRANSLATION_SCOPE, 'queries.error')) {
      timeout = setTimeout(() => {
        resetModalSubmit();
        resetNotifications();
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [notification]);

  useEffect(() => {
    if (error) {
      const errMsg = error.message || translate(null, TRANSLATION_SCOPE, 'queries.error');
      setNotification({ type: 'error', message: errMsg });
    } else {
      const formattedEvents = [];
      const allATBEventsByDay = {};
      const allDayPersonalEvents = [];
      const schedulesByType = {};
      let sortedRegularWorkingHours = {};

      if (data && has(data, 'providerProfile.schedules')) {
        const regularWorkingHoursByWeekdays = DAY_NAMES.reduce((acc, day) => ({ ...acc, [day]: {} }), {});

        const schedules = get(data, 'providerProfile.schedules', []);
        schedules.sort((a, b) => moment(a.startDateTime) - moment(b.startDateTime));
        map(schedules, schedule => {
          const { eventTypeCode, startDateTime, endDateTime, providerScheduleId, eventName, bufferTime } = schedule;

          const eventStart = moment(startDateTime);
          const startDateString = eventStart.format('D/MM/Y');
          const eventEnd = moment(endDateTime).subtract(
            eventTypeCode === scheduleTypes.PROVSCHEDEVENT_AWM.eventTypeCode && bufferTime > 0 ? bufferTime : 0,
            'minutes'
          );
          const eventDay = DAY_NAMES[eventStart.day()];

          const event = {
            ...schedule,
            id:            parseInt(providerScheduleId, 10),
            start:         eventStart.toDate(),
            end:           eventEnd.toDate(),
            title:         eventName,
            typeCode:      eventTypeCode,
            eventDuration: moment.duration(eventEnd.diff(eventStart)),
            startDateTime: eventStart,
            endDateTime:   eventEnd
          };
          const formattedEvent = formatSingleEvent(event);

          schedulesByType[eventTypeCode] = [...(schedulesByType[eventTypeCode] || []), event];

          if (isAcceptedBlock(event)) {
            formattedEvents.push(...formattedEvent);
          }

          if (eventTypeCode === scheduleTypes.PROVSCHEDEVENT_ATB.eventTypeCode) {
            const startDates = [startDateString].concat(formattedEvent.length > 1 ? [eventEnd.format('D/MM/Y')] : []);
            startDates.forEach(stDTtring => {
              allATBEventsByDay[stDTtring] = [...(allATBEventsByDay[stDTtring] || []), event];
            });
          }

          if (isAllDayPersonalEvent(event)) {
            allDayPersonalEvents.push(...formattedEvent.map(({ start }) => moment(start).format('D/MM/Y')));
          }

          if (isActiveRegularWorkingHour(schedule)) {
            const startEndString = eventStart.format('HHmm') + eventEnd.format('HHmm');
            regularWorkingHoursByWeekdays[eventDay][startEndString] = event;
          }
        });

        sortedRegularWorkingHours = Object.fromEntries(
          Object.entries(regularWorkingHoursByWeekdays).map(([weekDay, weekDayObj]) => {
            const sortedArray = Object.values(weekDayObj)
              .sort((a, b) => a.startDateTime.hour() - b.startDateTime.hour());
            return [weekDay, sortedArray];
          })
        );
      }

      const customBufferTime = get(data, 'providerProfile.customBufferTime');
      setCustomBuffer(Number.isInteger(customBufferTime) ? customBufferTime : false);
      setProviderShifts({
        loading,
        error,
        schedules: {
          formattedEvents,
          allATBEventsByDay,
          allDayPersonalEvents,
          schedulesByType,
          sortedRegularWorkingHours
        }
      });
    }
  }, [error, loading, data]);

  useEffect(() => {
    setProviderShifts(prevProviderShifts => ({
      ...prevProviderShifts,
      schedules: {
        ...prevProviderShifts.schedules,
        formattedEvents: [
          ...prevProviderShifts.schedules.formattedEvents.filter(
            ({ typeCode }) => typeCode !== eventTypeCodes.RECOMMENDED_WORKING_HOURS
          ),
          ...recommendedWorkingHours
        ]
      }
    }));
  }, [recommendedWorkingHours, recommendedWorkingHoursFlag]);

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      refetchEvents();
      refreshRecommendedWorkingHours();
    }, moment.duration(5, 'minutes').asMilliseconds());

    return () => clearInterval(refetchInterval);
  }, []);

  const createRecommendedWorkingHours = ({ start, end }) => {
    callCreateShift({
      variables: {
        provider_schedule_input_params: {
          eventName:      translate(null, 'my_schedule.events.recommended_working_hours', 'reason'),
          eventTypeCode:  eventTypeCodes.APPOINTMENT_TIME_BLOCK,
          startDateTime:  moment(start).toDate(),
          endDateTime:    moment(end).toDate(),
          timezoneCode:   moment.tz.guess(),
          workHourTypeCd: workHourTypeCodes.RECOMMENDED
        }
      }
    });
  };

  /*
   * Render
   */
  if (isRegularWorkingHoursInitiated || currentView === MyScheduleViews.SETTINGS) {
    return (
      <AvailabilitySettings
        notification={notification}
        setNotification={setNotification}
        regularWorkingHours={providerShifts.schedules.sortedRegularWorkingHours}
        specialWorkingHours={getSchedulesByType(scheduleTypes.PROVSCHEDEVENT_ATB.eventTypeCode)}
        personalEvents={getSchedulesByType(scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode)}
        onGoBack={handleGoBack}
        onSubmit={handleSubmit}
        resetNotifications={resetNotifications}
        isRegularWorkingHoursInitiated={isRegularWorkingHoursInitiated}
        resetInit={handleResetInit}
        validateProps={validateProps}
        validatePropsMessages={validatePropsMessages}
        saveShift={saveShiftV2}
        CREATE_SHIFT_EVENT={CREATE_SHIFT_EVENT}
        UPDATE_SHIFT_EVENT={UPDATE_SHIFT_EVENT}
        DELETE_SHIFT_EVENT={DELETE_SHIFT_EVENT}
        createRecommendedWorkingHours={createRecommendedWorkingHours}
        customBuffer={customBuffer}
        refetchQuery={refetchEvents}
        providerId={providerId}
      />
    );
  } else {
    return (
      <React.Fragment>
        <h1>{translate(null, 'my_schedule.main.title')}</h1>
        {notification && <Notification {...notification} />}
        <div className="myScheduleContainerWrapper">
          <MyScheduleContent
            key="content"
            apolloClient={apolloClient}
            scheduleTypes={scheduleTypes}
            onAddEditEvent={handleAddEditEvent}
            onOpenSettings={handleOpenSettings}
            providerShifts={providerShifts}
            setNotification={setNotification}
            createRecommendedWorkingHours={createRecommendedWorkingHours}
            canEditSchedule={canEditSchedule}
            loadSchedules={loadSchedules}
          />
        </div>
        {activeModal && activeModal.type === MyScheduleModals.PERSONAL_EVENTS && (
          <PersonalEventModal
            onModalClose={resetModalSubmit}
            isSubmitting={isSubmitting}
            onSubmit={handleSavePersonalEvent}
            onDelete={handleDeletePersonalEvent}
            initialWorkingHours={initialWorkingHours}
            event={activeModal.event}
            personalEvents={getSchedulesByType(scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode)}
          />
        )}
      </React.Fragment>
    );
  }
};

MyScheduleContainer.propTypes = {
  providerId:      PropTypes.number.isRequired,
  apolloClient:    PropTypes.object.isRequired,
  canEditSchedule: PropTypes.bool
};

export default MyScheduleContainer;
