import { get } from 'lodash';
export const clinicalAlertValidator = state => {
  const caSurvey = get(state, 'surveys.SURVEYMNEMONIC_ALERTCOVID19V2');
  const checkboxQ = get(caSurvey, 'SECTIONMNEMONIC_ALERTCOVID19V2.Q_1_1');
  const dateQ = get(caSurvey, 'SECTIONMNEMONIC_ALERTCOVID19V2.Q_1_2');
  const recentLabQ = get(caSurvey, 'SECTIONMNEMONIC_ALERTCOVID19V2.Q_1_3');
  const settingQ = get(caSurvey, 'SECTIONMNEMONIC_ALERTCOVID19V2.Q_1_4');
  const doYouPlanToPrescribePaxlovidFlag = get(caSurvey, 'SECTIONMNEMONIC_ALERTCOVID19V2.Q_1_5');
  const iAttestCheckbox = get(caSurvey, 'SECTIONMNEMONIC_ALERTCOVID19V2.Q_1_6');

  const symptoms = get(checkboxQ, 'CDCSYMPTOM');
  const symptomDate = get(dateQ, 'DATE');

  const caFlag = get(state, 'clinicalAlertForm.mainInquiry');

  const checkboxValPresent = checkboxQ && Object.values(checkboxQ).includes(true);
  const recentLabValPresent = recentLabQ && Object.values(recentLabQ).includes(true);
  const settingQValPresent = settingQ && Object.values(settingQ).includes(true);

  // TODO make this configurable
  const dateWithinRange = (date, start) => start < date && date <= new Date();

  return {
    caSurveyPresence: {
      invalid:  caFlag === 'Y' && !(checkboxValPresent || recentLabValPresent || settingQValPresent),
      message:  'You must fill out the epidemiological risk assesment followup section.',
      isInline: true
    },
    // specifing that validation message should show up at this question
    'SECTIONMNEMONIC_ALERTCOVID19V2.Q_1_1': {
      invalid:  caFlag === 'Y' && !checkboxValPresent,
      message:  'You must check at least one checkbox in the COVID19 assesment section',
      isInline: true
    },
    'SECTIONMNEMONIC_ALERTCOVID19V2.Q_1_2': {
      invalid:
        caFlag === 'Y' && !dateWithinRange(new Date(symptomDate), new Date(2019, 1, 1)) && get(checkboxQ, 'CDCSYMPTOM'),
      message:  'You must enter a valid date in which symptoms started',
      isInline: true
    },
    alertFlgPresence: {
      invalid:  caFlag === '',
      message:  'You must fill out the epidemiological risk assesment section.',
      isInline: true
    },
    'SECTIONMNEMONIC_ALERTCOVID19V2.Q_1_6': {
      invalid:
        doYouPlanToPrescribePaxlovidFlag &&
        doYouPlanToPrescribePaxlovidFlag.YES &&
        iAttestCheckbox &&
        !iAttestCheckbox.PAXLOVID_ATTESTED,
      message:  'You must attest that the patient meets all Paxlovid criteria',
      isInline: true
    },
    'SECTIONMNEMONIC_ALERTCOVID19V2.Q_1_5': {
      invalid:
        caFlag === 'Y' &&
        doYouPlanToPrescribePaxlovidFlag &&
        !doYouPlanToPrescribePaxlovidFlag.YES &&
        !doYouPlanToPrescribePaxlovidFlag.NO,
      message:  'You must answer if you plan to prescribe Paxlovid for this patient',
      isInline: true
    }
  };
};
