import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@td/shared_utils';
import RegularWorkingHoursTimes from './regular-working-hours-times';

// Icons
const addIcon = require('../../assets/images/add_icon.svg');

// I18n
const TRANSLATION_SCOPE = 'my_schedule.modals.regular_working_hours.days_and_times_section';

const RegularWorkingHoursDay = ({
  day,
  hours = [],
  isChecked,
  onCheckDay,
  onTimeChange,
  onDeleteTime,
  onAddTime,
  onApplyToAll
}) => (
  <div className="timeBlock">
    <div className="dateAndTime">
      <div className="checkbox">
        <label className={`dayName ${isChecked && 'checked'}`} htmlFor={`checkbox-${day}`}>
          <input id={`checkbox-${day}`} type="checkbox" value={day} checked={isChecked} onChange={onCheckDay} />
          {day}
        </label>
      </div>

      {isChecked ? (
        <React.Fragment>
          <div className="dayTimeSection">
            {hours.map(regularWorkingHour => (
              <RegularWorkingHoursTimes
                key={`newTime-${day}-${regularWorkingHour.providerScheduleId}`}
                timeBlock={regularWorkingHour}
                onDelete={timeBlock => onDeleteTime(timeBlock)}
                onTimeChange={timeBlock => onTimeChange(timeBlock)}
              />
            ))}
          </div>
          <div className="dayActions">
            <input
              type="image"
              className="addAction"
              src={addIcon}
              onClick={onAddTime}
              title={translate(null, 'my_schedule.settings_page', 'actions.add')}
            />
            <button className="applyToAllBtn" onClick={onApplyToAll}>
              {translate(null, TRANSLATION_SCOPE, 'apply_to_all_label')}
            </button>
          </div>
        </React.Fragment>
      ) : (
        <span className="unavailableLable">{translate(null, TRANSLATION_SCOPE, 'unavaliable_label')}</span>
      )}
    </div>
  </div>
);

RegularWorkingHoursDay.propTypes = {
  day:          PropTypes.string.isRequired,
  hours:        PropTypes.array,
  isChecked:    PropTypes.bool,
  onCheckDay:   PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  onDeleteTime: PropTypes.func.isRequired,
  onAddTime:    PropTypes.func.isRequired,
  onApplyToAll: PropTypes.func.isRequired
};

export default RegularWorkingHoursDay;
