import { of, from } from 'rxjs';
import { ofType } from 'redux-observable';
import { debounceTime, mergeMap, map, startWith, takeUntil, flatMap, catchError } from 'rxjs/operators';
import { v4DrugsPath } from 'app/config/paths';
import { authToken, teladocApi, TdApiRoutes } from '@td/api';

import { success } from 'app/notifications/actions';

import {
  FETCH_CLINICAL_MEDICATIONS,
  SUBMIT_CLINICAL_MEDICATION_FORM,
  SUBMIT_CLINICAL_MEDICATION_FORM_SUCCESS,
  SUBMIT_CLINICAL_MEDICATION_FORM_CANCEL,
  FETCH_MEDICATION_LIST_REQUEST,
  FETCH_MEDICATION_LIST_CANCEL,
  FETCH_CLINICAL_MEDICATIONS_SUCCESS
} from './actionTypes';

import { actionTypes } from 'medication_service_ui/dist/benefits';

import {
  fetchClinicalMedicationsSuccess,
  fetchClinicalMedications,
  fetchClinicalMedicationsStart,
  fetchClinicalMedicationsFail,
  submitClinicalMedicationFormSuccess,
  submitClinicalMedicationFormStart,
  submitClinicalMedicationFormFail,
  fetchMedicationListSuccess,
  fetchMedicationListStart,
  fetchMedicationListFail,
  setSurescriptsUpdatedFlag
} from './actions';

const { FETCH_BENEFITS_INFORMATION_SUCCESS, FETCH_BENEFITS_INFORMATION_FAIL } = actionTypes;

const buildFetchMedicationListRequest = (payload, state$) => {
  const { term } = payload;
  const token = authToken.get();
  const {
    settings: { drugSearchAvailable, regionCode }
  } = state$.value;

  if (drugSearchAvailable) {
    return from(teladocApi.get(v4DrugsPath(term, regionCode), {}, token));
  } else {
    throw new Error('Drug search is not available');
  }
};

export const fetchMedicationListEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_MEDICATION_LIST_REQUEST),
    debounceTime(350),
    mergeMap(action =>
      buildFetchMedicationListRequest(action.payload, state$).pipe(
        map(response => fetchMedicationListSuccess(response)),
        takeUntil(action$.pipe(ofType(FETCH_MEDICATION_LIST_CANCEL))),
        startWith(fetchMedicationListStart()),
        catchError(error => of(fetchMedicationListFail(error)))
      )
    )
  );

const fetchClinicalMedRequest = memberId => {
  const url = TdApiRoutes.member.clinicalMedicationsPath(memberId);
  const params = {};
  const token = authToken.get();

  return from(teladocApi.get(url, params, token));
};

export const fetchClinicalMedEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_CLINICAL_MEDICATIONS),
    mergeMap(action => {
      const {
        settings: {
          memberParams: { memberId: defaultMemberId }
        }
      } = state$.value;
      const memberId = action.payload.memberId || defaultMemberId;

      return fetchClinicalMedRequest(memberId).pipe(
        map(response => fetchClinicalMedicationsSuccess(response)),
        takeUntil(action$.pipe(ofType('FETCH_CLINICAL_MEDICATION_CANCEL'))),
        startWith(fetchClinicalMedicationsStart()),
        catchError(error => of(fetchClinicalMedicationsFail(error)))
      );
    })
  );

const postClinicalMedication = formData => {
  const {
    memberId,
    newMedicationMhdQuestionId,
    newMedicationMhdAnswerId,
    drugName,
    drugQuantity,
    drugFrequency,
    drugCode,
    drugTypeCode,
    activelyTakingFlag
  } = formData;

  const params = {
    request: {
      survey_responses: [
        {
          question_id:          newMedicationMhdQuestionId,
          ref_survey_answer_id: newMedicationMhdAnswerId,
          responses:            [
            {
              drug_nm:             drugName,
              drug_mmdc:           '',
              drug_mmdc_text:      '',
              drug_ndc_code:       drugCode,
              drug_quantity:       drugQuantity,
              drug_type_code:      drugTypeCode,
              drug_form:           '',
              drug_form_text:      '',
              drug_instructions:   '',
              drug_notes:          '',
              drug_substitutions:  0,
              drug_refill_type:    '',
              drug_refills:        '',
              drug_frequency:      drugFrequency,
              drug_type:           'medication',
              actively_taking_flg: activelyTakingFlag
            }
          ]
        }
      ]
    },
    medical_history_disclosure: {
      request: {
        survey_responses: [
          {
            question_id:          newMedicationMhdQuestionId,
            ref_survey_answer_id: newMedicationMhdAnswerId,
            responses:            [
              {
                drug_nm:             drugName,
                drug_mmdc:           '',
                drug_mmdc_text:      '',
                drug_ndc_code:       drugCode,
                drug_quantity:       drugQuantity,
                drug_form:           '',
                drug_form_text:      '',
                drug_instructions:   '',
                drug_notes:          '',
                drug_substitutions:  0,
                drug_refill_type:    '',
                drug_refills:        '',
                drug_frequency:      drugFrequency,
                drug_type:           'medication',
                actively_taking_flg: activelyTakingFlag
              }
            ]
          }
        ]
      }
    }
  };

  const url = TdApiRoutes.memberMhds(memberId);
  return from(teladocApi.post(url, params, authToken.get()));
};

export const submitClinicalMedicationFormEpic = action$ =>
  action$.pipe(
    ofType(SUBMIT_CLINICAL_MEDICATION_FORM),
    mergeMap(action =>
      postClinicalMedication(action.payload).pipe(
        flatMap(response =>
          of(submitClinicalMedicationFormSuccess(response), success('Medication added successfully!'))
        ),
        takeUntil(action$.pipe(ofType(SUBMIT_CLINICAL_MEDICATION_FORM_CANCEL))),
        startWith(submitClinicalMedicationFormStart()),
        catchError(error => of(submitClinicalMedicationFormFail(error)))
      )
    )
  );

export const refetchClinicalMedicationsEpic = action$ =>
  action$.pipe(
    ofType(SUBMIT_CLINICAL_MEDICATION_FORM_SUCCESS, FETCH_BENEFITS_INFORMATION_SUCCESS),
    debounceTime(150),
    mergeMap(() => of(fetchClinicalMedications()))
  );

export const setSurescriptsUpdatedFlagEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_CLINICAL_MEDICATIONS_SUCCESS, FETCH_BENEFITS_INFORMATION_FAIL),
    mergeMap(({ type }) => {
      let flag;
      switch (type) {
        case FETCH_CLINICAL_MEDICATIONS_SUCCESS: {
          // If we're actually trying to fetch benefits, then turn on the updating flag
          const {
            benefits: { fetched, pending }
          } = state$.value;
          flag = !pending || fetched;
          break;
        }
        case FETCH_BENEFITS_INFORMATION_FAIL: {
          flag = true;
          break;
        }
        default: {
          flag = true;
        }
      }
      return of(setSurescriptsUpdatedFlag(flag));
    })
  );
