import React, { Fragment, useState, useMemo, useCallback } from 'react';
import TeladocModal from 'app/TeladocModal';
import styles from './styles.module.scss';
import './styles.scss';
import { Form, Formik } from 'formik';
import { useFetcher } from '@td/shared_utils';
import { authToken, TdApiRoutes, teladocApi } from '@td/api';
import { get } from 'lodash';
import { healthCardValidateSchema } from 'app/canada/health-card/validation';
import {
  CardIdField,
  ExpiryField,
  ProvinceField,
  SubmitButtons,
  Notification
} from 'app/canada/health-card/components';
import { mapValuesToFormData, mapPropToValues } from 'app/canada/health-card/utils';
import PropTypes from 'prop-types';
import {
  BUTTONS,
  HEALTH_CARD_EXPIRY,
  HEALTH_CARD_PROVINCE,
  HEALTH_CARD_ID,
  HEALTH_CARD_COUNTRY,
  NOTIFICATION_SUCCESS_MESSAGE,
  NOTIFICATION_ERROR_MESSAGE,
  SELECT_PROVINCE
} from 'app/canada/health-card/constants';


const HealthCardContainer = ({ memberParams }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const {
    healthCardInfo,
    healthCardEnabled,
    healthCardRequired,
    memberId,
    idFormatsByState,
    expiryObligationsByState,
    homeStateProvince,
    stateMismatchDisclaimer
  } = memberParams;

  const validationConstants = {
    ID_FORMAT_BY_STATE:          idFormatsByState.CAN,
    EXPIRY_OBLIGATIONS_BY_STATE: expiryObligationsByState.CAN
  };

  const clearMessageData = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  const updateModal = useCallback(() => {
    clearMessageData();
    setIsShowModal(curr => !curr);
  }, []);

  const { data } = useFetcher(
    TdApiRoutes.countriesInfo(),
    { with_states: true, context: 'profile' }
  );

  const availableStates = useMemo(() => {
    if (data) {
      const countries = get(data, 'countries', []);
      const canadaData = countries.find(country => country.countryCode === 'CAN');

      const placeholderValue = { stateName: SELECT_PROVINCE, stateCode: '', disabled: !!healthCardRequired };

      return [placeholderValue, ...canadaData.states].map(({ stateName, stateCode, disabled }) =>
        ({ value: stateCode, text: stateName, disabled }));
    }

    return [];
  }, [data]);

  const updateHealthCardRequest = async ({ url, params }) => {
    try {
      const result = await teladocApi.put(url, params, authToken.get());
      if (result.status === 200) setSuccessMessage(NOTIFICATION_SUCCESS_MESSAGE);
      const timeout = setTimeout(() => {
        setSuccessMessage(null);
        setIsShowModal(null);
      }, 5000);
      return () => clearTimeout(timeout);
    } catch (e) {
      setErrorMessage(NOTIFICATION_ERROR_MESSAGE);
    }
  };

  const submitForm = values => {
    clearMessageData();

    const params = mapPropToValues(values);
    const url = `v4/members/${memberId}/update_health_card`;
    updateHealthCardRequest({ url, params });
  };

  const setInitFormValues = () => mapValuesToFormData(healthCardInfo);

  if (!healthCardEnabled) {
    return null;
  }

  return (
    <Fragment>
      <button
        onClick={updateModal}
        className={styles.openButton}
        data-testid="health-card-open-button"
      >
        Edit Health Card
      </button>
      {isShowModal &&
        <TeladocModal
          title={`Health Card${healthCardRequired ? '' : ' (Optional)'}`}
          isOpen={isShowModal}
          onClose={updateModal}
          className={styles.modalHeader}
        >
          {(errorMessage || successMessage) &&
            (
              <Notification
                styles={styles}
                errorMessage={errorMessage}
                successMessage={successMessage}
              />
            )
          }
          <Formik
            validationSchema={() => healthCardValidateSchema(
              { required: !!healthCardRequired, validationConstants })}
            initialValues={setInitFormValues()}
            onSubmit={submitForm}
          >
            {formikProps => (
              <Form>
                <div className={styles.contentWrapper}>
                  <div className={styles.fieldContainer}>
                    <p className={styles.fieldLabel} data-testid="country-label">{HEALTH_CARD_COUNTRY}</p>
                    <p className={styles.fieldText} data-testid="country-name">Canada</p>
                  </div>
                  <div className={styles.fieldContainer}>
                    <ProvinceField
                      styles={styles}
                      label={HEALTH_CARD_PROVINCE}
                      {...formikProps}
                      options={availableStates}
                      homeStateProvince={homeStateProvince}
                      stateMismatchDisclaimer={stateMismatchDisclaimer}
                    />
                  </div>
                  <div className={styles.fieldContainer}>
                    <CardIdField
                      styles={styles}
                      label={HEALTH_CARD_ID}
                      {...formikProps}
                    />
                  </div>
                  <div className={styles.fieldContainer}>
                    <ExpiryField
                      label={HEALTH_CARD_EXPIRY}
                      styles={styles}
                      {...formikProps}
                    />
                  </div>
                  <SubmitButtons
                    onCancel={updateModal}
                    styles={styles}
                    label={BUTTONS}
                  />
                </div>
              </Form>
            )
            }
          </Formik>
        </TeladocModal>}
    </Fragment>
  );
};

HealthCardContainer.propTypes = {
  memberParams: PropTypes.shape({
    healthCardInfo: PropTypes.object,
    healthCardEnabled: PropTypes.bool,
    healthCardRequired: PropTypes.bool,
    memberId: PropTypes.number,
    idFormatsByState: PropTypes.object,
    expiryObligationsByState: PropTypes.object
  })
};

export default HealthCardContainer;
