import { combineEpics } from 'redux-observable';

import chiefComplaintEpics from './chief-complaint-epics';
import durationSeverityProgressionEpics from './duration-severity-progression-epics';
import symptomEpics from './symptom-epics';
import symptomRequestEpics from './symptom-request-epics';

export default combineEpics(
  chiefComplaintEpics,
  durationSeverityProgressionEpics,
  symptomEpics,
  symptomRequestEpics
);
