// Let's try to keep the convention of this file to camelcase(name of rails helper path)

const formularyV4Member = memberId =>
  `/v4/members/${memberId}/formulary`;

const medHistoryV4Member = memberId =>
  `/v4/members/${memberId}/med_history`; // both fills and claims

const medicationV4InfoPath = drugId =>
  `/v4/medications/${drugId}/info`;

const providerOrderV4Path = consultationId =>
  `/v4/consultations/${consultationId}/provider_orders`;

const providerOrderRevokeV4Path = (consultationId, providerOrderId) =>
  `/v4/consultations/${consultationId}/provider_orders/${providerOrderId}/revoke`;
const statusRemedyFailoversPath = 'remedy_failovers/status';

const providerConsultationsQuickRxPath = consultationId =>
  `/consultations/${consultationId}/quick_rx`;

const v4DrugsPath = (term, regionCode = 'us') =>
  `/v4/drugs?term=${encodeURIComponent(term)}&region_code=${encodeURIComponent(regionCode)}`;

const providerListStatesV4Path = providerId =>
  `/v4/providers/${providerId}/states`;

export {
  formularyV4Member,
  medHistoryV4Member,
  medicationV4InfoPath,
  providerOrderV4Path,
  providerOrderRevokeV4Path,
  providerConsultationsQuickRxPath,
  providerListStatesV4Path,
  statusRemedyFailoversPath,
  v4DrugsPath
};
