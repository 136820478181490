import React from 'react';
import PropTypes from 'prop-types';
import ClinicalMedicationListItem from './ClinicalMedicationListItem';

const ClinicalMedicationList = props => {
  const { medicationList, surescriptsUpdated } = props;
  const showAlertLegend = medicationList.some(e => e.validForAlerts === false);

  return (
    <div className="medication-list">
      <table className="table">
        <thead>
          <tr>
            <th>Medication</th>
            <th>Dosage</th>
            <th>Direction</th>
            <th>Status</th>
            <th>Reported Date</th>
            <th>Reported By</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {medicationList.length !== 0 ? (
            medicationList.map(medication => (
              <ClinicalMedicationListItem
                key={`clinical-medication-list-item-idx-${medication.clinicalMedicationId}`}
                surescriptsUpdated={surescriptsUpdated}
                medication={medication}
              />
            ))
          ) : (
            <tr>
              <td>
                <p>There is no teladoc reported medication.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {showAlertLegend && (
        <div className="medication-alert__legend">
          <span>Drug Interaction check not available</span>
        </div>
      )}
    </div>
  );
};

ClinicalMedicationList.propTypes = {
  medicationList:     PropTypes.array.isRequired,
  surescriptsUpdated: PropTypes.bool.isRequired
};

export default ClinicalMedicationList;
