import chiefComplaint from './chief-complaint-reducer';
import chiefComplaintRequest from './chief-complaint-request-reducer';
import chiefComplaintSuggestions from './chief-complaint-suggestions-reducer';
import complaintsClasses from './complaints-classes-reducer';
import complaintsClassesRequest from './complaints-classes-request-reducer';
import durationSeverityProgression from './duration-severity-progression-reducer';
import durationSeverityProgressionRequest from './duration-severity-progression-request-reducer';
import durationSeverityProgressionForm from './duration-severity-progression-form-reducer';
import symptoms from './symptoms-reducer';
import symptomsRequest from './symptoms-request-reducer';
import symptomsForm from './symptoms-form-reducer';
import symptomsFocus from './symptoms-focus-reducer';
import symptomClassesRequest from './symptom-classes-request-reducer';
import symptomAccordions from './symptom-accordions-reducer';
import symptomsAdded from './symptoms-added-reducer';
import symptomTabs from './symptom-tabs-reducer';
import symptomAddForm from './symptom-add-form-reducer';
import additionalNotesForm from './additional-notes-form-reducer';
import clinicalAlertForm from './clinical-alert-form-reducer';
import clinicalAlertsActivatedFlag from './clinical-alerts-activated-flag-reducer';

export default {
  chiefComplaint,
  chiefComplaintRequest,
  chiefComplaintSuggestions,
  clinicalAlertForm,
  clinicalAlertsActivatedFlag,
  complaintsClasses,
  complaintsClassesRequest,
  durationSeverityProgression,
  durationSeverityProgressionRequest,
  durationSeverityProgressionForm,
  symptoms,
  symptomsRequest,
  symptomsForm,
  symptomsFocus,
  symptomClassesRequest,
  symptomAccordions,
  symptomsAdded,
  symptomTabs,
  symptomAddForm,
  additionalNotesForm
};
