/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get, find } from 'lodash';
import { translate } from '@td/shared_utils';
import { scheduleTypeMethods, scheduleTypes } from '../../my-schedule/constants';
import calendarIcon from './../../assets/images/event_details/calendar.svg';
import clockIcon from './../../assets/images/event_details/clock.svg';
import languageIcon from './../../assets/images/event_details/language.svg';
import { AlertCircleIcon } from '../../my-schedule/icons';
import { eventTypeCodes } from '../constants';

const TRANSLATION_SCOPE = 'my_schedule.event_details';

const EventDetails = ({
  event,
  onReschedule,
  onStartVisit,
  onEditEvent,
  onApprove,
  onCancel,
  canEditSchedule
}) => {
  const isFuture = moment(event.startDateTime).isAfter(moment());
  const hasDetails = event.typeCode === scheduleTypes.PROVSCHEDEVENT_AWM.eventTypeCode;
  const editOnly = event.typeCode === scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode && canEditSchedule;
  const eventDuration = event.eventDuration.asMinutes();
  const afterPosition = event.afterPosition;
  const topPosition = event.allDayEventFlg ? 3 : event.topPosition || 3;
  const showFromAndToDates =
    !event.allDayEventFlg || moment(event.startDateTime).date() !== moment(event.endDateTime).date();
  const formattedFromDate = moment(event.startDateTime).format('dddd, MMMM D');
  const timeFrom = moment(event.startDateTime).format('h:mm A');
  const formattedToDate = moment(event.endDateTime).format('dddd, MMMM D');
  const timeTo = moment(event.endDateTime).format('h:mm A');
  const timezoneName = moment.tz.guess();
  const timezone = moment.tz(timezoneName).zoneAbbr();
  const dayInWeek = moment(event.start).day();
  const isLeft = dayInWeek > 3 && dayInWeek !== 7;

  const {
    id: consultationId,
    communicationMethod,
    reasonForVisit,
    isNewVisit,
    consultStatus,
    messageRoomId,
    supportNewMessaging,
    visitType,
    reschedulable: canReschedule,
    memberName,
    memberId,
    allowStartInterval
  } = get(event, 'actor') || {};

  const { providerId, schedulingTimeSpanDays: reschedulingIntervals } = event;
  const rescheduleProps = {
    memberId,
    providerId,
    reschedulingIntervals
  };

  const statusTypeMethod = find(scheduleTypeMethods, ['status', consultStatus]);

  const isCanceledOrCompleted =
    statusTypeMethod &&
    (statusTypeMethod.status === scheduleTypeMethods.completed.status ||
      statusTypeMethod.status === scheduleTypeMethods.canceled.status);

  const canStart =
    moment()
      .add(allowStartInterval || 0, 'seconds')
      .isSameOrAfter(moment(event.startDateTime)) && moment(event.startDateTime).add(10, 'minutes') > moment();
  const eventStatusLabel = isCanceledOrCompleted ? statusTypeMethod.short : null;
  const commTypeMethod = scheduleTypeMethods[String(communicationMethod).toLowerCase()];
  const eventCommunicationMethodIcon = communicationMethod
    ? require(`./../../assets/images/event_details/${commTypeMethod.icon}.svg`)
    : null;

  const eventStatusIcon = statusTypeMethod ? require(`../../assets/images/${statusTypeMethod.icon}.svg`) : null;

  const canSendMessage =
    supportNewMessaging && messageRoomId && moment(event.startDateTime).subtract(24, 'hours') < moment();

  return (
    <div
      className={`eventDetailsWrapper ${event.typeCode} ${isLeft && 'left'} after-position-${afterPosition}`}
      style={{ top: `${topPosition}px` }}
    >
      <div className={`content ${event.typeCode} ${eventStatusLabel}`}>
        <div className="header">
          {isNewVisit && <span className="newLabel">{translate(null, TRANSLATION_SCOPE, 'new_label')}</span>}
          {eventStatusIcon && <img className="eventStatusIcon" src={eventStatusIcon} alt={statusTypeMethod.label} />}
          <h4 className="eventTitle">
            {event.typeCode === eventTypeCodes.RECOMMENDED_WORKING_HOURS ? (
              <React.Fragment>
                <AlertCircleIcon width={20} height={20} fill="#D87F00" />

                {translate(null, 'my_schedule.events.recommended_working_hours', 'title')}
              </React.Fragment>
            ) : (
              <React.Fragment>{memberName || event.title}</React.Fragment>
            )}
            {eventStatusLabel && <span className="eventStatusLabel">{eventStatusLabel}</span>}
          </h4>
          {eventCommunicationMethodIcon && (
            <div className="communicationMethod">
              <span>{commTypeMethod.label}</span>
              <img className="communicationMethodIcon" src={eventCommunicationMethodIcon} alt={commTypeMethod.label} />
            </div>
          )}
        </div>
        <div className="dateDetails">
          {showFromAndToDates ? (
            <React.Fragment>
              <div className="eventDateWrapper">
                <img className="calendarIcon" src={calendarIcon} role="presentation" />
                <div className="date">
                  {`${translate(null, TRANSLATION_SCOPE, 'from_label')} `}
                  <span>{`${formattedFromDate}${event.allDayEventFlg ? '' : `, ${timeFrom}`}`}</span>
                </div>
              </div>
              <div className="eventDateWrapper">
                <div className="emptyImg" />
                <div className="date">
                  {`${translate(null, TRANSLATION_SCOPE, 'to_label')} `}
                  <span>{`${formattedToDate}${event.allDayEventFlg ? '' : `, ${timeTo}`}`}</span>
                </div>
              </div>
              <div className="eventDurationWrapper">
                <img className="clockIcon" src={clockIcon} role="presentation" />
                <div className="duration">
                  {event.typeCode !== eventTypeCodes.RECOMMENDED_WORKING_HOURS ? (
                    <React.Fragment>
                      {`${translate(null, TRANSLATION_SCOPE, 'duration_label')} `}
                      <span>
                        {event.eventDuration.asDays() > 1
                          ? `${Math.ceil(event.eventDuration.asDays())} ${translate(null, TRANSLATION_SCOPE, 'days')}`
                          : `${eventDuration} ${translate(null, TRANSLATION_SCOPE, 'minutes_abbrev')}`}
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {timeFrom} - {timeTo}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="eventDateWrapper">
                <img className="calendarIcon" src={calendarIcon} role="presentation" />
                <div className="date">{formattedFromDate}</div>
              </div>
              <div className="eventDurationWrapper">
                <img className="clockIcon" src={clockIcon} role="presentation" />
                <div className="duration">
                  {event.allDayEventFlg ? (
                    translate(null, TRANSLATION_SCOPE, 'all_day')
                  ) : (
                    <React.Fragment>
                      {timeFrom} - {timeTo}
                      {event.typeCode !== eventTypeCodes.RECOMMENDED_WORKING_HOURS && (
                        <span>
                          {' '}
                          ({eventDuration} {translate(null, TRANSLATION_SCOPE, 'minutes_abbrev')})
                        </span>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}

          {event.typeCode !== eventTypeCodes.RECOMMENDED_WORKING_HOURS && (
            <div className="timezoneWrapper">
              <img className="languageIcon" src={languageIcon} role="presentation" />
              <div className="timezone">
                {translate(null, TRANSLATION_SCOPE, 'time_zone')} <span> {timezone}</span>
              </div>
            </div>
          )}
        </div>

        {event.typeCode === eventTypeCodes.RECOMMENDED_WORKING_HOURS && (
          <div className="eventDescription">
            {translate(null, 'my_schedule.events.recommended_working_hours', 'description')}
          </div>
        )}

        {hasDetails && (
          <div className="visitDetailsWrapper">
            <h4 className="subtitle">{translate(null, TRANSLATION_SCOPE, 'visit_details')}</h4>
            <div className="visitType">
              <div className="typeDetails">
                <div>
                  {translate(null, TRANSLATION_SCOPE, 'visit_type')}: <span>{visitType}</span>
                </div>
              </div>
              {!isCanceledOrCompleted && consultationId && (
                <a href={`/consultations/${consultationId}/ehr_preview_modal`} className="preview-patient-ehr">
                  {translate(null, TRANSLATION_SCOPE, 'view_ehr')}
                </a>
              )}
            </div>
            <div className="visitReason">
              <div className="visitReasonDetails">
                <div>
                  {translate(null, TRANSLATION_SCOPE, 'reason_details')}: <span>{reasonForVisit}</span>
                </div>
              </div>
              {canSendMessage && consultationId && (
                <a href={`/care_team_messages/${messageRoomId}?consultation_id=${consultationId}`} className="linkto">
                  {translate(null, TRANSLATION_SCOPE, 'send_message')}
                </a>
              )}
            </div>
            <div className="visitId">
              <div>
                {translate(null, TRANSLATION_SCOPE, 'visit_id')}:{' '}
                {isCanceledOrCompleted && consultationId ? (
                  <div className="linkto">
                    <a href={`/history/consultation/${consultationId}`}>{consultationId}</a>
                  </div>
                ) : (
                  consultationId
                )}
              </div>
            </div>
          </div>
        )}

        {!isCanceledOrCompleted && (
          <div className="eventDetailsActionsWrapper">
            {event.typeCode !== eventTypeCodes.RECOMMENDED_WORKING_HOURS ? (
              <React.Fragment>
                {editOnly && isFuture && (
                  <button onClick={onEditEvent} className="button primary noMarginBtn" id="editButton">
                    {translate(null, TRANSLATION_SCOPE, 'edit')}
                  </button>
                )}
                {canStart && (
                  <button onClick={onStartVisit} className="button primary noMarginBtn" id="startButton">
                    {translate(null, TRANSLATION_SCOPE, 'start_visit')}
                  </button>
                )}
                {canReschedule && isFuture && (
                  <button
                    onClick={() => onReschedule({ consultationId, rescheduleProps })}
                    className="button primary marginBtn"
                    id="rescheduleButton"
                    title={translate(null, TRANSLATION_SCOPE, 'reschedule')}
                  >
                    {translate(null, TRANSLATION_SCOPE, 'reschedule')}
                  </button>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button onClick={onApprove} className="button primary">
                  {translate(null, 'my_schedule.events.recommended_working_hours.actions', 'add')}
                </button>
                <button onClick={onCancel} className="button secondary">
                  {translate(null, 'my_schedule.events.recommended_working_hours.actions', 'cancel')}
                </button>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

EventDetails.propTypes = {
  event: PropTypes.shape({
    start:         PropTypes.instanceOf(Date).isRequired,
    startDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string])
      .isRequired,
    endDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string])
      .isRequired,
    afterPosition:  PropTypes.string,
    topPosition:    PropTypes.number,
    allDayEventFlg: PropTypes.bool,
    title:          PropTypes.string.isRequired,
    typeCode:       PropTypes.string.isRequired,
    eventDuration:  PropTypes.object.isRequired
  }).isRequired,
  onReschedule: PropTypes.func.isRequired,
  onStartVisit: PropTypes.func.isRequired,
  onEditEvent:  PropTypes.func.isRequired,
  onApprove: PropTypes.func,
  onCancel: PropTypes.func,
  canEditSchedule: PropTypes.bool
};

export default EventDetails;
