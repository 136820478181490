/* eslint-disable global-require */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get, find } from 'lodash';
import { translate } from '@td/shared_utils';
import { scheduleTypeMethods } from '../../my-schedule/constants';
import EventDetails from './event-details';

const TRANSLATION_SCOPE = 'my_schedule.event_details';

const CustomCalendarEvent = ({
  event,
  onEditEvent,
  showDetails,
  setShowDetails,
  scheduleTypes,
  setActiveModal,
  canEditSchedule
}) => {
  const wrapperRef = useRef(null);

  /*
   * Callbacks
   */
  const handleEditEvent = currentEvent => {
    setShowDetails(null);
    onEditEvent(currentEvent);
  };

  const onShowDetails = mouseEvent => {
    const isEventContainer = mouseEvent.target.className.includes('rbc-event-content') || mouseEvent.target.className.includes('provider-calendar-event');

    if (mouseEvent && isEventContainer && wrapperRef && wrapperRef.current) {
      let containerTop = null;
      let containerBot = null;
      const monthView = document.getElementsByClassName('rbc-month-view');
      if (monthView && monthView[0] && monthView[0]) {
        containerTop = monthView[0].getBoundingClientRect().top;
        containerBot = monthView[0].getBoundingClientRect().bottom;
      } else {
        containerTop = document.getElementsByClassName('rbc-time-content')[0].getBoundingClientRect().top;
        containerBot = document.getElementsByClassName('rbc-time-content')[0].getBoundingClientRect().bottom;
      }
      const top = wrapperRef.current.getBoundingClientRect().top;
      const scroll = -1 * wrapperRef.current.offsetTop + top;
      let middleFix = mouseEvent.clientY - containerTop > 20 ? -25 : -2;
      if (containerBot - mouseEvent.clientY < 178) {
        middleFix = -180 + containerBot - mouseEvent.clientY;
        event.afterPosition = 'bot';
      } else {
        event.afterPosition = 'top';
      }
      event.topPosition = mouseEvent.clientY - scroll + middleFix;
    }
    if (!showDetails) {
      setShowDetails(event.id);
    }
  };

  const handleStartVisit = ({ id }) => {
    if (id) {
      window.open(`/consultations/${id}/init_review?load_ease=true`, '_blank');
    }
  };

  const handleReschedule = ({ consultationId, rescheduleProps }) => {
    if (consultationId) {
      setActiveModal({ modal: 'RESCHEDULE_MODAL', consultationId, rescheduleProps });
    }
  };

  const useOutsideAlerter = ref => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(currentEvent) {
        if (
          showDetails &&
          ref.current &&
          !ref.current.contains(currentEvent.target) &&
          currentEvent.target.nodeName !== 'BUTTON' &&
          currentEvent.target.nodeName !== 'A'
        ) {
          setShowDetails(null);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  /*
   * Constants
   */
  useOutsideAlerter(wrapperRef);

  const { PROVSCHEDEVENT_AWM, PROVSCHEDEVENT_PERSONALEVENT } = scheduleTypes;

  const { communicationMethod, isNewVisit, consultStatus, memberName } = get(event, 'actor') || {};

  const useWhiteIcons = event.typeCode === PROVSCHEDEVENT_AWM.eventTypeCode;
  const isUnavailability = event.typeCode === PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode;
  const isSmallTimeSlot = event.eventDuration.asMilliseconds() <= (3600 * 1000) / 2;
  const isCanceled = consultStatus === scheduleTypeMethods.canceled.status;

  const iconType = isCanceled || !useWhiteIcons ? '' : '_white';

  const commTypeMethod = scheduleTypeMethods[String(communicationMethod).toLowerCase()];
  const eventCommunicationMethodIcon =
    communicationMethod && commTypeMethod ? require(`../../assets/images/${commTypeMethod.icon}${iconType}.svg`) : null;

  const statusTypeMethod = find(scheduleTypeMethods, ['status', consultStatus]);
  const eventStatusIcon =
    consultStatus && statusTypeMethod ? require(`../../assets/images/${statusTypeMethod.icon}${iconType}.svg`) : null;

  /*
   * Render
   */
  return (
    <div
      className={`provider-calendar-event ${event.allDayEventFlg && 'full-time-event'} ${isCanceled &&
        'canceled-event'}`}
      key={event.id}
      onClick={onShowDetails}
      ref={wrapperRef}
    >
      <div className="provider-calendar-event-heading-wrapper">
        {isCanceled && <div className="canceled-event-side-indicator" />}
        {eventStatusIcon && (
          <div className="provider-calendar-event-icon status-icon">
            <img src={eventStatusIcon} alt={statusTypeMethod && statusTypeMethod.label} />
          </div>
        )}
        <div className="provider-calendar-event-title-wrapper">
          {isNewVisit && !isCanceled && (
            <span className="new-label">{translate(null, TRANSLATION_SCOPE, 'new_label')}</span>
          )}
          <span className={`title ${isCanceled && 'canceled'} ${event.typeCode}`}>{memberName || event.title}</span>
        </div>
        {eventCommunicationMethodIcon && (
          <div className="provider-calendar-event-icon communication-method-icon">
            <img src={eventCommunicationMethodIcon} alt={commTypeMethod && commTypeMethod.label} />
          </div>
        )}
      </div>
      {!isSmallTimeSlot && !isCanceled && !event.allDayEventFlg && !isUnavailability && (
        <div className="provider-calendar-event__time">
          {moment(event.start).format('h:mm A')} - {moment(event.end).format('h:mm A')} (
          {Math.floor(event.eventDuration.asMilliseconds() / 1000 / 60)}{' '}
          {translate(null, TRANSLATION_SCOPE, 'minutes_abbrev')})
        </div>
      )}
      {showDetails &&
        showDetails === event.id &&
        [PROVSCHEDEVENT_AWM.eventTypeCode, PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode].includes(event.typeCode) && (
          <EventDetails
            event={event}
            onReschedule={handleReschedule}
            onStartVisit={() => handleStartVisit(event.actor)}
            onEditEvent={() => handleEditEvent(event)}
            canEditSchedule={canEditSchedule}
          />
        )}
    </div>
  );
};

CustomCalendarEvent.propTypes = {
  event: PropTypes.shape({
    id:    PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    actor: PropTypes.shape({
      communicationMethod: PropTypes.string,
      isNewVisit:          PropTypes.bool,
      consultStatus:       PropTypes.string
    }),
    typeCode:       PropTypes.string.isRequired,
    title:          PropTypes.string.isRequired,
    start:          PropTypes.instanceOf(Date).isRequired,
    end:            PropTypes.instanceOf(Date).isRequired,
    eventDuration:  PropTypes.object.isRequired,
    allDayEventFlg: PropTypes.bool,
    afterPosition:  PropTypes.string,
    topPosition:    PropTypes.number
  }).isRequired,
  onEditEvent:    PropTypes.func.isRequired,
  showDetails:    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setShowDetails: PropTypes.func.isRequired,
  scheduleTypes:  PropTypes.shape({
    PROVSCHEDEVENT_AWM:           PropTypes.shape({ eventTypeCode: PropTypes.string.isRequired }).isRequired,
    PROVSCHEDEVENT_PERSONALEVENT: PropTypes.shape({ eventTypeCode: PropTypes.string.isRequired }).isRequired
  }).isRequired,
  setActiveModal: PropTypes.func.isRequired,
  canEditSchedule: PropTypes.bool
};

export default CustomCalendarEvent;
