import { useQuery } from '@apollo/client';

import ANY_CONSULTATION_COMPLETED from '../../patient-volume-manager/queries/any_consult_completed.graphql';

const useAnyConsultationCompleted = () => {
  const { data, loading, error } = useQuery(ANY_CONSULTATION_COMPLETED);
  const { currentUserProvider: { anyConsultationCompleted } = {} } = data || {};

  return {
    data: anyConsultationCompleted,
    loading,
    error
  };
};

export default useAnyConsultationCompleted;
