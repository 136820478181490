import React from 'react';
import PropTypes from 'prop-types';
import ReviewLineItem from './review-line-item';

const TeladocMedicationReview = ({
  medications,
  responseOptions,
  addToPendingReviewList,
  pendingReviewList,
  updatePendingReviewList
}) => (
  <div className="medication-review">
    <div className="medication-review__list">
      <div className="medication-review-list__header">
        <div className="medication-review-list__header--item">Drug Name</div>
        <ul className="medication-review-list__header--item-group">
          <li className="medication-review-list__header--item">Dosage</li>
          <li className="medication-review-list__header--item">Currently Taking</li>
          <li className="medication-review-list__header--item">Reported Date</li>
        </ul>
      </div>
      {medications.length > 0 ? (
        medications.map((medication, index) => (
          <ReviewLineItem
            key={`${medication.clinicalMedicationId}-${index}`}
            medication={medication}
            responseOptions={responseOptions}
            addToPendingReviewList={addToPendingReviewList}
            pendingReviewList={pendingReviewList}
            updatePendingReviewList={updatePendingReviewList}
          />
        ))
      ) : (
        <p>No medication to review</p>
      )}
    </div>
  </div>
);

TeladocMedicationReview.defaultProps = {
  responseOptions: ['Yes', 'No']
};

TeladocMedicationReview.propTypes = {
  medications:             PropTypes.array.isRequired,
  responseOptions:         PropTypes.array.isRequired,
  pendingReviewList:       PropTypes.array.isRequired,
  addToPendingReviewList:  PropTypes.func.isRequired,
  updatePendingReviewList: PropTypes.func.isRequired
};

export default TeladocMedicationReview;
