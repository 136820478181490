import React from 'react';
import PropTypes from 'prop-types';
import DataTableSortButton from '../DataTableSortButton';
import Loader from 'app/components/Loader';

const SortableColumn = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

const DataTable = React.memo(({ columns, data, sortColumn, sortDirection, onSort, loading, rowStyles }) => {
  if (!data.length) return <p className="no-data-message">{I18n.t('dashboard.consult_queues.no_rows')}</p>;

  return (
    <React.Fragment>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {columns.map(({ id, name, sortable = false, width }, index) => (
                <th key={index} style={{ width: width || 'auto', verticalAlign: 'initial' }}>
                  <SortableColumn
                    condition={sortable}
                    wrapper={children => (
                      <DataTableSortButton
                        id={id}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        onClick={onSort}
                      >
                        {children}
                      </DataTableSortButton>
                    )}
                  >
                    {name}
                  </SortableColumn>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map(({ rowProps = {}, ...row }, index) => (
                <tr key={index} style={{ verticalAlign: 'top', ...(rowStyles && rowStyles(row)) }}>
                  {columns.map(({ selector }, index) => (
                    <td key={index}>{selector(row)}</td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
    </React.Fragment>
  );
});

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      selector: PropTypes.func.isRequired,
      width: PropTypes.string,
      sortable: PropTypes.bool
    })
  ),
  data: PropTypes.array.isRequired,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string,
  onSort: PropTypes.func,
  loading: PropTypes.bool,
  rowStyles: PropTypes.func
};

export default DataTable;
