export const STATUS_MAP = {
  CARETEAMRLTNSTATUS_NEW:         I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status.new'),
  CARETEAMRLTNSTATUS_NEWTOYOU:    I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status.new_to_you'),
  CARETEAMRLTNSTATUS_ESTABLISHED: I18n.t(
    'patient_case_load_mgmt_mvp.patient_list.care_team_relation_status.established'
  ),
  CARETEAMRLTNSTATUS_INACTIVE:   I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status.inactive'),
  CARETEAMRLTNSTATUS_TERMINATED: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status.terminated')
};

export const DEFAULT_SORTING_COLUMN = 'personName';

export const DEFAULT_SORTING_DIRECTION = 'asc';

export const DEFAULT_FILTERS = {
  status: [
    'CARETEAMRLTNSTATUS_NEW',
    'CARETEAMRLTNSTATUS_NEWTOYOU',
    'CARETEAMRLTNSTATUS_ESTABLISHED',
    'CARETEAMRLTNSTATUS_INACTIVE'
  ]
};
