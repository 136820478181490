import moment from 'moment-timezone';

const PATIENT_LIST_DATE_FORMAT = 'L LT z';

export const getBrowserTimezone = (date, format = PATIENT_LIST_DATE_FORMAT) => {
  const localTimezone = moment.tz.guess();

  return date
    ? moment(date)
        .tz(localTimezone)
        .format(format)
    : '';
};
