import React, { useState, useRef, useEffect } from 'react';
import { map } from 'lodash';
import './datepicker.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setHours, setMinutes } from 'date-fns';

const defaultTimeIntervals = 15; // time-list gap between 2 times (in minutes)
const defaultMinDate = new Date();
const defaultMinHour = 0;
const defaultMaxHour = 24;

const Calendar = ({
  timezone,
  selectedDate,
  timeIntervals,
  onConfirmDate,
  onDateChange,
  yearsRange,
  maxDate,
  minDate,
  getRef,
  excludedDates,
  customInputComponent,
  editModeIndex,
  lockedSlots,
  filterDate,
  filterTime,
  minTime,
  maxTime
}) => {
  // Calculate initially selected date and set first / nearest available "time" depending on shown time intervals
  const calculateStartDate = () => {
    const currentTime = selectedDate ? new Date(selectedDate) : new Date();
    const intervals = timeIntervals || defaultTimeIntervals;
    const numberOfIntervalChunks = 60 / intervals;
    const curentMinute = currentTime.getMinutes();
    let nextAvailableTime;
    for (let chunk = 1; chunk <= numberOfIntervalChunks; chunk++) {
      const nextMinuteSlot = chunk * intervals;
      const prevMinuteSlot = (chunk - 1) * intervals;
      if (typeof nextAvailableTime === 'undefined' && curentMinute < nextMinuteSlot) {
        nextAvailableTime = nextMinuteSlot;
      }
    }
    currentTime.setMinutes(nextAvailableTime);
    currentTime.setSeconds(0);
    return currentTime;
  };
  // States
  const [startDate, setStartDate] = useState(calculateStartDate());

  useEffect(() => {
    // Expose picker ref to the parent component once is loaded
    getRef && getRef(datepickerRef);
    getClassNameAttribute();
  });

  // Register DatePicker Ref
  let datepickerRef = useRef(null);

  /** Register locale example:
    const defaultLocale = 'en-US';
    const localeObj = require(`date-fns/locale/${locale || defaultLocale}`).default;
    registerLocale('locale', localeObj);
    * Pass locale as propery to the <DatePicker / > component
    * e.g: locale="locale"
  */

  // Close on select and save date
  const handleConfirmDate = () => {
    datepickerRef.cancelFocusInput();
    datepickerRef.setOpen(false);
    if (onConfirmDate) {
      onConfirmDate(startDate, editModeIndex);
    }
  };

  // Filter available times
  const filterPassedTime = time => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  // Triggers on date or time change
  const handleDateChange = date => {
    setStartDate(date);
    if (onDateChange) {
      onDateChange(date);
    }
  };

  // Filter locked slots by selected date
  const filteredLockedSlots = [];
  map(lockedSlots, lockedSlot => {
    const formattedLockedSlot = new Date(lockedSlot);
    const selectedYear = startDate.getFullYear();
    const selectedMonth = startDate.getMonth();
    const selectedDay = startDate.getDate();
    const lockedYear = formattedLockedSlot.getFullYear();
    const lockedMonth = formattedLockedSlot.getMonth();
    const lockedDay = formattedLockedSlot.getDate();
    if (selectedYear === lockedYear && selectedMonth === lockedMonth && selectedDay === lockedDay) {
      return filteredLockedSlots.push(formattedLockedSlot);
    }
  });

  // Get element by its className and append element placeholder for locked icon
  const getClassNameAttribute = () => {
    document.querySelectorAll('.customLockedSlot').forEach(e => e.remove());
    const element = document.getElementsByClassName('react-datepicker__time-list-item--disabled');
    map(element, el => {
      const lockIconNode = document.createElement('span');
      lockIconNode.classList.add('customLockedSlot');
      el.appendChild(lockIconNode);
    });
  };

  // Embeded Header with custom action on the top of picker
  const customHeader = () => (
    <div className="customHeaderWrapper">
      <p className="timezoneLabel">{`Time zone: ${timezone || ''}`}</p>
      <a href="#" className="confirmButton" onClick={handleConfirmDate}>
        Done
      </a>
    </div>
  );

  // Embeded and customized input / open handler
  const CustomInput = ({ value, onClick }) => (
    <button className="example-custom-input" onClick={onClick}>
      Select Date and Time
    </button>
  );

  const currentDate = new Date();
  const maxDateBoundary = maxDate ? currentDate.setDate(currentDate.getDate() + maxDate) : null;

  const minmaxTimes = {};
  // Only both should be present
  if (minTime !== undefined || maxTime !== undefined) {
    minTime = minTime || defaultMinHour;
    maxTime = maxTime || defaultMaxHour;
    minmaxTimes.minTime = setHours(setMinutes(new Date(), 0), minTime);
    // For correct datepicker setup, maxTime should be set as one minute before desired time
    minmaxTimes.maxTime = setHours(setMinutes(new Date(), 59), --maxTime);
  }

  return (
    <div className="calendarWrapper calendarWrapper2">
      <DatePicker
        ref={r => (datepickerRef = r)}
        selected={startDate}
        onChange={date => handleDateChange(date)}
        showTimeSelect
        // inline => show picker without open handele (for testing purposes)
        timeIntervals={timeIntervals || defaultTimeIntervals}
        dateFormat="MM/dd/yyyy h:mm aa"
        calendarClassName="weeks-bg"
        showMonthDropdown
        showYearDropdown
        excludeTimes={filteredLockedSlots}
        timeCaption="time"
        excludeDates={excludedDates}
        minDate={minDate || defaultMinDate}
        maxDate={maxDateBoundary}
        showDisabledMonthNavigation
        filterTime={filterTime || filterPassedTime}
        customInput={customInputComponent}
        shouldCloseOnSelect={false}
        {...(filterDate && { filterDate })}
        {...minmaxTimes}
      >
        <div className="calendarCustomBodyWrapper">{customHeader()}</div>
      </DatePicker>
    </div>
  );
};

export default Calendar;
