import React from 'react';
import PersistentAccordion from '../PersistentAccordion';
import RequestedAndProposedVisitsTable from './RequestedAndProposedVisitsTable';
import DataTablePagination from '../../../data-table/DataTablePagination';
import { useRequestedAndProposedVisitsTable } from './RequestedAndProposedVisitsTableProvider';

const RequestedAndProposedVisitsTableContainer = () => {
  const {
    loading,
    consultations,
    hasNextPage,
    fetchNextPage,
    metadata: { totalEntries }
  } = useRequestedAndProposedVisitsTable();

  return (
    <PersistentAccordion
      storageKey="td-consult-queues-requested-table-open"
      title={I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.title')}
      subtitle={`${totalEntries} ${I18n.t('dashboard.consult_queues.requested_and_proposed_visit_table.rows')}`}
      loading={loading}
    >
      <RequestedAndProposedVisitsTable data={consultations} />
      <DataTablePagination loading={loading} hasNextPage={hasNextPage} onClick={fetchNextPage} />
    </PersistentAccordion>
  );
};

export default RequestedAndProposedVisitsTableContainer;
