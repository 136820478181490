import React from 'react';
import PropTypes from 'prop-types';
import TeladocModal from '../../TeladocModal';
import './consultation-attestation-confirm.scss';

const ConsultationAttestationConfirm = ({
  actionsDisabled = false,
  isModalOpen = false,
  cancelCallback,
  confirmCallback
}) => (
  <TeladocModal
    className="attestationConfirmModal"
    title="Are you sure you want to cancel this visit?"
    isOpen={isModalOpen}
    onClose={cancelCallback}
  >
    <div className="content">
      <p>This visit will be cancelled</p>
    </div>
    <div className="actionButtons">
      <button
        id="dialogCancelButton"
        key="dialogCancelButton"
        className="secondary button"
        onClick={cancelCallback}
        disabled={actionsDisabled}
      >
        NO
      </button>
      <button
        id="dialogConfirmButton"
        key="dialogConfirmButton"
        className="button"
        onClick={confirmCallback}
        disabled={actionsDisabled}
      >
        YES
      </button>
    </div>
  </TeladocModal>
);

ConsultationAttestationConfirm.propTypes = {
  actionsDisabled: PropTypes.bool,
  isModalOpen:     PropTypes.bool,
  cancelCallback:  PropTypes.func,
  confirmCallback: PropTypes.func
};

export default ConsultationAttestationConfirm;
