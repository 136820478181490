import React, { useEffect, useLayoutEffect, useRef } from 'react';
import HTMLElementRelocator from '../../components/html-element-relocator';
import { NameAttributes } from '../dom-integration';
import { I18n } from '@td/shared_utils';
import { FOLLOWUP_APPOINTMENT_OPTIONS, FOLLOWUP_SECTIONS } from '../constants';
import FieldError from './field-error';
import styles from './consult-followup.module.scss';
import cx from 'classnames';

export const TRANSLATION_SCOPE = 'consult_followup.appointment';

const FollowupAppointment = ({
  className,
  providerScheduledAllowed,
  noFurtherCareMaxDays,
  fieldErrors,
  noFurtherCareDays,
  setNoFurtherCareDays,
  selectedValue,
  setSelectedValue,
  patientManagementToolsV2Enabled,
  isMentalHealth
}) => {
  const pclmIntegrationEnabled = patientManagementToolsV2Enabled && isMentalHealth;

  const bindRadioAttrs = value => ({
    name:     'interview[instruction_radio]',
    type:     'radio',
    value,
    checked:  selectedValue === value,
    onChange: () => setSelectedValue(value)
  });

  const didInitializeProviderScheduled = useRef(false);
  useLayoutEffect(() => {
    if (
      selectedValue === FOLLOWUP_APPOINTMENT_OPTIONS.PROVIDER_SCHEDULED &&
      providerScheduledAllowed &&
      !didInitializeProviderScheduled.current
    ) {
      window._consultFollowupAppointmentInit();
      didInitializeProviderScheduled.current = true;
    }
  });

  const prevSelectedValue = usePrevious(selectedValue);
  useEffect(() => {
    if (
      prevSelectedValue === FOLLOWUP_APPOINTMENT_OPTIONS.PROVIDER_SCHEDULED &&
      selectedValue !== FOLLOWUP_APPOINTMENT_OPTIONS.PROVIDER_SCHEDULED
    ) {
      window.removeSelectedFollowUp && window.removeSelectedFollowUp();
    }
  }, [selectedValue]);

  return (
    <div className={className}>
      <h3 className="p-0 behavior-header">
        <I18n scope={TRANSLATION_SCOPE} text={pclmIntegrationEnabled ? 'mental_health_heading' : 'heading'} />
      </h3>
      <div className="mt-2 font-bold">
        <I18n scope={TRANSLATION_SCOPE} text="select_one" />
      </div>
      <div className="mt-2 flex flex-col">
        {providerScheduledAllowed && (
          <div
            className={cx(
              'followup_option appointment-option appointment-option-provider-scheduled',
              styles.providerScheduledAppointment
            )}
          >
            <label className="flex">
              <input
                id="interview_instruction_radio_with_followup_date"
                {...bindRadioAttrs(FOLLOWUP_APPOINTMENT_OPTIONS.PROVIDER_SCHEDULED)}
              />
              <I18n
                scope={TRANSLATION_SCOPE}
                text={pclmIntegrationEnabled ? 'provider_scheduled_pclm' : 'provider_scheduled'}
              />
            </label>
            <HTMLElementRelocator
              className="ml-2"
              initialPositionContainer=".consult_form #followup_consult_option"
              currentPosition={
                selectedValue === FOLLOWUP_APPOINTMENT_OPTIONS.PROVIDER_SCHEDULED ? 'relocated' : 'initial'
              }
            />
          </div>
        )}

        <div className="appointment-option appointment-option-no-further-care">
          <label className="flex items-center" style={{ margin: '1px 0 8px' }}>
            <input
              {...bindRadioAttrs(FOLLOWUP_APPOINTMENT_OPTIONS.NO_APPOINTMENT)}
              id="interview_instruction_radio_no_appointment"
            />
            <span>
              <I18n
                scope={TRANSLATION_SCOPE}
                text={
                  pclmIntegrationEnabled ? 'no_further_care_mental_health.before_input' : 'no_further_care.before_input'
                }
              />
            </span>
            <input
              className="no_further_care_input"
              maxLength={2}
              name={NameAttributes.appointment.noFurtherCareDays.html}
              type="text"
              value={noFurtherCareDays}
              onChange={e => setNoFurtherCareDays(e.target.value)}
            />
            <span>
              <I18n
                scope={TRANSLATION_SCOPE}
                text={
                  pclmIntegrationEnabled ? 'no_further_care_mental_health.after_input' : 'no_further_care.after_input'
                }
                options={{ upper_bound: noFurtherCareMaxDays }}
              />
            </span>
            <input
              type="hidden"
              name={NameAttributes.appointment.noFurtherCareDaysHidden.html}
              value="INSTRUCTION_CHOICE"
            />
          </label>
          <div className="no-further-care-errors">
            {(fieldErrors || [])
              .filter(e => e.validatorKey === FOLLOWUP_SECTIONS.NO_APPOINTMENT)
              .map((e, i) => (
                <FieldError style={{ margin: '8px 0' }} key={i} message={e.message} />
              ))}
          </div>
        </div>

        {!patientManagementToolsV2Enabled && (
          <label className="appointment-option flex">
            <input
              id="interview_instruction_radio_additional_care"
              {...bindRadioAttrs(FOLLOWUP_APPOINTMENT_OPTIONS.ADDITIONAL_CARE)}
            />
            <I18n scope={TRANSLATION_SCOPE} text="additional_care" />
          </label>
        )}
      </div>
    </div>
  );
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default FollowupAppointment;
