import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import UPDATE_ACCEPTING_NEW_PATIENTS_VALUE from './mutations/update_accepting_new_patients_value.graphql';
import ACCEPTING_NEW_PATIENT_ALERT from './mutations/accepting_new_patient_alert.graphql';
import ANP_AUTO_DECLINE_ALERT from './mutations/anp_auto_decline_alert.graphql';
import FETCH_ACCEPTING_NEW_PATIENTS_VALUE from './queries/fetch_accepting_new_patients_value.graphql';
import FETCH_PATIENTS_LIMIT from './queries/fetch_patients_limit.graphql';
import FETCH_PRACTICE_TEAM from './queries/fetch_practice_team.graphql';
import { ACCEPTING_NEW_PATIENT_REASON } from './constants';
import { extractData, getMessages } from './helper';
import PatientVolumeManager from './patient-volume-manager';
import Banner from '../components/banner/banner';


const MH_PRACTICE_TEAM = "National"

const PatientVolumeManagerContainer = ({
  providerId,
  role,
  maxOverallPatientsLimit,
  maxDailyNewPatientsLimit,
  isAnyConsultCompleted
}) => {

  const [modalData, setModalData] = useState({
    role,
    providerId,
    displayModal:                false,
    defaultOverall:              maxOverallPatientsLimit,
    defaultDaily:                maxDailyNewPatientsLimit,
    overallPatientsLimit:        undefined,
    dailyNewPatientLimit:        undefined,
    practiceTeamParticipantId:   undefined,
    practiceTeamId:              undefined,
    currentPatients:             undefined
  });
  const [toggleData, setToggleData] = useState({
    acceptingNewPatients:         undefined,
    acceptingNewPatientsReasonCd: undefined,
    tooltipMessage:               [],
  });
  const [summaryData, setSummaryData] = useState({
    todayNewPatients:             undefined,
    todayEstablishedPatients:     undefined,
    currentPatients:              undefined,
    isAnyConsultCompleted
  });

  const {
    error: patientsLimitError,
    data: patientsLimitData
  } = useQuery(FETCH_PATIENTS_LIMIT, { variables: { providerId } });
  const {
    error: acceptingPatientsError,
    data: acceptingPatientsData
  } = useQuery(FETCH_ACCEPTING_NEW_PATIENTS_VALUE, { variables: { providerId } });
  const { 
    error: practiceTeamError, 
    data: practiceTeamData 
  } = useQuery(FETCH_PRACTICE_TEAM, { variables: { MH_PRACTICE_TEAM } });

  const [saveToggleValue] = useMutation(UPDATE_ACCEPTING_NEW_PATIENTS_VALUE);
  const [closeTheBanner] = useMutation(ACCEPTING_NEW_PATIENT_ALERT);
  const [closeAdBanner] = useMutation(ANP_AUTO_DECLINE_ALERT);

  // Renders a notification banner that informs the user if it's no longer accepting new patients
  // because her license is expired or an Admin disabled that option.
  const renderBanner = (bannerMessages, displayBanner, displayAD) => {
    if (!displayAD && !displayBanner) return;
    const onClose = () => {
      if (displayBanner) closeTheBanner({ variables: { providerId } });
      if (displayAD) closeAdBanner({ variables: { providerId: providerId, turnOn: false} });
    };
    const container = document.querySelector('#react_notification_banner');
    if (container) ReactDOM.render(<Banner message={bannerMessages} onClose={onClose} />, container);
  };

  const setInitialToggleValue = data => {
    const acceptingNewPatients = data.acceptingNewPatientsFlg === 'Y';
    let bannerMessages = [], tooltipMessage = [];
    if (!acceptingNewPatients) {
      const reasonCd = data.acceptingNewPatientsReasonCd;
      const licenseExpired = data.licenseStatus === 'Expired';
      const personType = data.personType;
      const displayBanner = data.bannerDisplayFlag;
      const displayAD = data.autoDeclineBannerDisplayFlag;

      ( { bannerMessages, tooltipMessage } = getMessages(reasonCd, personType, licenseExpired) );
      if (bannerMessages.length > 0) renderBanner(bannerMessages, displayBanner, displayAD);
    } 
    setToggleData({...toggleData, ...data, tooltipMessage, acceptingNewPatients})
  };

  const updateToggleValue = value => {
    const reasonCd = value ?
                     ACCEPTING_NEW_PATIENT_REASON.on_by_provider :
                     ACCEPTING_NEW_PATIENT_REASON.off_by_provider;
    setToggleData({...toggleData, acceptingNewPatients: value })
    saveToggleValue({
      variables: {
        providerId, 
        toggleValue: value,
        acceptingNewPatientsReasonCd: reasonCd
      }
    });
  };

  // Send a listener to the button in patient-list/PatientListManageLimits.js
  const addListenerOnPatientLimitButton = () => {
    const limitButton = document.querySelector('#patient-limit-button');
    if (limitButton) {
      limitButton.addEventListener(
        'click',
        () => setModalData({...modalData, displayModal: true})
      );
    }
  };

  useEffect(() => {
    if (acceptingPatientsData && !acceptingPatientsError) {
      setInitialToggleValue(acceptingPatientsData.fetchPatientToggleValue);
    }
    if (patientsLimitData && !patientsLimitError) {
      addListenerOnPatientLimitButton();
      const {modal, summary} = extractData(
        patientsLimitData.provider,
        providerId,
        maxOverallPatientsLimit,
        maxDailyNewPatientsLimit,
        isAnyConsultCompleted
      );
      setModalData({...modalData, ...modal});
      setSummaryData({...summaryData, ...summary});
    }
    if (practiceTeamData && !practiceTeamError) {
      const id = practiceTeamData.practiceTeamSearch.nodes[0].id;
      setModalData({...modalData, practiceTeamId: id})
    }
  }, 
  [acceptingPatientsData,
   patientsLimitData,
   practiceTeamData
  ]);

  return (
    <React.Fragment>
      {(patientsLimitData && acceptingPatientsData)  && (
        <PatientVolumeManager
          toggle={{toggleData, updateToggleValue}}
          modal={{modalData, setModalData}}
          summaryData={summaryData}
        />
      )}
    </React.Fragment>
  );
};

PatientVolumeManagerContainer.propTypes = {
  providerId:               PropTypes.number,
  role:                     PropTypes.string,
  maxOverallPatientsLimit:  PropTypes.number,
  maxDailyNewPatientsLimit: PropTypes.number,
  isAnyConsultCompleted:    PropTypes.bool
};

export default PatientVolumeManagerContainer;
