import React from 'react';
import PropTypes from 'prop-types';
import PendingMedicationItem from './pending-medication-item';

const PendingMedicationList = props => {
  const { pendingMeds, removeHandler, editHandler } = props;

  return (
    <div className="pending-medication-list">
      <table className="table">
        <thead>
          <tr>
            <th>Medication</th>
            <th>Dosage</th>
            <th>Direction</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pendingMeds.map(medication => (
            <PendingMedicationItem
              key={`pending-drug-idx-${medication.drugName}`}
              medication={medication}
              removeHandler={removeHandler}
              editHandler={editHandler}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

PendingMedicationList.propTypes = {
  pendingMeds:   PropTypes.array.isRequired,
  removeHandler: PropTypes.func.isRequired,
  editHandler:   PropTypes.func.isRequired
};

export default PendingMedicationList;
