import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup } from 'medication_service_ui';
import moment from 'moment';

const InterviewItem = ({
  drugName,
  drugQuantity,
  serviceDate,
  createdAt,
  selectedOption,
  responseOptions,
  validForReview = true,
  showQuantity = true,
  onChangeFuncHandler,
  clinicalMedicationId
}) => (
  <div className="medication-items interview-row">
    <div className="item item--featured">
      {drugName}
    </div>

    <div className="interview-row__item-group">
      {
        showQuantity && <div className="interview-row__item-group__item">
          {drugQuantity}
        </div>
      }

      <div className="interview-row__item-group__item question">
        { validForReview &&
          <RadioGroup
            groupName={`${drugName}-${clinicalMedicationId}`}
            onChangeFunc={onChangeFuncHandler}
            options={responseOptions}
            selectedOption={selectedOption}
          />
        }
      </div>
      <div className="interview-row__item-group__item">
        {
          serviceDate ?
            moment(serviceDate).format('MM/DD/YYYY') :
            moment(createdAt).format('MM/DD/YYYY')
        }
      </div>
    </div>
  </div>
);

InterviewItem.propTypes = {
  validForReview:       PropTypes.bool,
  showQuantity:         PropTypes.bool,
  drugName:             PropTypes.string.isRequired,
  drugQuantity:         PropTypes.string.isRequired,
  serviceDate:          PropTypes.any,
  createdAt:            PropTypes.any.isRequired,
  selectedOption:       PropTypes.string.isRequired,
  responseOptions:      PropTypes.array.isRequired,
  onChangeFuncHandler:  PropTypes.func.isRequired,
  clinicalMedicationId: PropTypes.number.isRequired
};

export default InterviewItem;

