import React from 'react';
import PropTypes from 'prop-types';
import { patientSummaryCard } from '../../constants';

const PatientSummaryCard = ({
  title,
  captionOne,
  captionTwo,
  shouldChangePatientCountColor = false,
  summaryData: { todayNewPatients, currentPatients, todayEstablishedPatients, overallPatientsLimit }
}) => {
  const isTitleTodaysPatients = title === patientSummaryCard.title_todays_patients;
  const countOne = isTitleTodaysPatients ? todayNewPatients : currentPatients;
  const countTwo = isTitleTodaysPatients ? todayEstablishedPatients : overallPatientsLimit;

  return (
    <React.Fragment>
      <div className="summaryCard">
        <div className="summaryCardContentWrapper">
          <div className="cardTitle">{title}</div>
          <div className="summaryCardContent">
            <div>
              <div
                className={
                  countOne >= countTwo && shouldChangePatientCountColor ? 'limitReachedPatients' : 'patientCount'
                }
              >
                {countOne}
              </div>
              <div className="patientStatus">{captionOne}</div>
            </div>
            <div className="verticalDivider" />
            <div>
              <div
                className={
                  countOne >= countTwo && shouldChangePatientCountColor ? 'limitReachedPatients' : 'patientCount'
                }
              >
                {countTwo}
              </div>
              <div className="patientStatus">{captionTwo}</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

PatientSummaryCard.propTypes = {
  title:                         PropTypes.string,
  captionOne:                    PropTypes.string,
  captionTwo:                    PropTypes.string,
  shouldChangePatientCountColor: PropTypes.bool,
  patientSummaryData:            PropTypes.shape({
    todayNewPatients:            PropTypes.number,
    currentPatients:             PropTypes.number,
    todayEstablishedPatients:    PropTypes.number,
    overallPatientsLimit:        PropTypes.number
  })
};

export default PatientSummaryCard;
