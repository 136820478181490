import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { STATUS_MAP } from '../constants';

const PatientListStatus = ({ statusCd }) => {
  const statusClass = classnames({
    'new-status': ['CARETEAMRLTNSTATUS_NEW', 'CARETEAMRLTNSTATUS_NEWTOYOU'].includes(statusCd)
  });

  return <p className={statusClass}>{STATUS_MAP[statusCd]}</p>;
};

PatientListStatus.propTypes = {
  status: PropTypes.string
};

export default PatientListStatus;
