import React from 'react';
import { connect } from 'react-redux';
import { setFollowupReduxState } from '../state-managment/redux';
import ConsultFollowup from './consult-followup';
import useConsultFollowup from '../state-managment/use-consult-followup';

const ConsultFollowupContainer = ({
  memberId,
  consultSpecialtyName,
  stateCode,
  noFurtherCareMinDays,
  noFurtherCareMaxDays,
  initialValues,
  reduxState,
  setReduxState,
  providerScheduledAppointmentAllowed,
  communicationMethod,
  memberHasPcp,
  continueTreatment,
  patientManagementToolsV2Enabled,
  isMentalHealth,
  fieldErrors,
  terminateReasonCode
}) => {
  const logic = useConsultFollowup({
    memberId,
    consultSpecialtyName,
    stateCode,
    reduxState,
    setReduxState,
    noFurtherCareMinDays,
    noFurtherCareMaxDays,
    initialValues,
    communicationMethod,
    providerScheduledAppointmentAllowed
  });

  return (
    <ConsultFollowup
      {...logic}
      providerScheduledAppointmentAllowed={providerScheduledAppointmentAllowed}
      noFurtherCareMaxDays={noFurtherCareMaxDays}
      memberHasPcp={memberHasPcp}
      continueTreatment={continueTreatment}
      patientManagementToolsV2Enabled={patientManagementToolsV2Enabled}
      isMentalHealth={isMentalHealth}
      fieldErrors={fieldErrors}
      consultSpecialtyName={consultSpecialtyName}
      terminateReasonCode={terminateReasonCode}
    />
  );
};

const ConsultFollowupContainerConnected = connect(
  state => {
    const followup = state.consultNavigation.items.find(i => i.key === 'followup');
    return { reduxState: state.consultFollowup, fieldErrors: (followup && followup.errors) || [] };
  },
  dispatch => ({ setReduxState: upcommingState => dispatch(setFollowupReduxState(upcommingState)) })
)(ConsultFollowupContainer);

export default ConsultFollowupContainerConnected;
