import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import cx from 'classnames';

import { translate } from '@td/shared_utils';
import { safetyPlanStyle } from '../styles';
import { ANSWER_NAME, QUESTION_NAME, SAFETY_PLAN_QUESTION_CD, TEXT_FIELD_NAME, VALUE_NAME } from '../constants';

const SafetyPlan = ({ placeholder, styles, isRequired, updateMentalStatusExamInput, minChars, value }) => {
  const [chars, setChars] = useState(0);

  const onInputChange = e => {
    const value = e.target.value;
    setChars(value.length);
    updateMentalStatusExamInput(SAFETY_PLAN_QUESTION_CD, value);
  };

  const validationLabelStyle = () => {
    if (chars < minChars) {
      return get(styles, ['validationLabelStyle', 'required'], null);
    }
    return get(styles, ['validationLabelStyle', 'normal'], null);
  };

  const TRANSLATION_KEY = 'ehr.mh.objective_tab.mental_status_exam_survey.safety_plan';

  const titleTranslationKey = () => (isRequired ? 'title_required' : 'title');

  return (
    <div className={cx(safetyPlanStyle, get(styles, 'container'))}>
      <input type="hidden" name={QUESTION_NAME} value={SAFETY_PLAN_QUESTION_CD} />
      <input type="hidden" name={ANSWER_NAME} value={SAFETY_PLAN_QUESTION_CD} />
      <input type="hidden" name={VALUE_NAME} value="Y" />
      <h4 className={get(styles, 'label')}>{translate(null, TRANSLATION_KEY, titleTranslationKey())}</h4>
      <p>{translate(null, TRANSLATION_KEY, 'description')}</p>
      <p>{translate(null, TRANSLATION_KEY, 'note')}</p>
      <textarea
        className={cx(get(styles, 'textarea'), 'survey_safety_plan_text_area')}
        placeholder={placeholder}
        name={TEXT_FIELD_NAME}
        onChange={onInputChange}
        value={value}
      />
      {isRequired && (
        <p className={validationLabelStyle()}>
          {translate(null, TRANSLATION_KEY, 'validation_message', { minimum_chars: minChars })} ({chars})
        </p>
      )}
    </div>
  );
};

SafetyPlan.propTypes = {
  placeholder:                 PropTypes.string,
  styles:                      PropTypes.object,
  isRequired:                  PropTypes.bool,
  updateMentalStatusExamInput: PropTypes.func,
  minChars:                    PropTypes.number
};

export default SafetyPlan;
