import validate from 'validate.js';

const exclusionList = ['Select one...', ''];

const constraints = {
  drugQuantity: {
    presence: {
      message:    '^Please indicate the dosage',
      allowEmpty: false
    },
    numericality: {
      message:     '^Dosage must be a valid number',
      strict:      true,
      greaterThan: 0
    }
  },
  drugQuantityUnit: {
    presence: {
      message:    '^Please indicate dosage unit',
      allowEmpty: false
    }
  },
  drugName: {
    presence: {
      message:    '^Please enter the medication',
      allowEmpty: false
    },
    length: {
      minimum: 3,
      message: '^Medication must have a minimum length of 3 characters'
    }
  },
  drugFrequency: {
    presence: {
      message:    '^Please indicate the directions',
      allowEmpty: false
    },
    exclusion: {
      within:  exclusionList,
      message: '^Directions are not valid'
    }
  },
  activelyTakingFlag: {
    inclusion: {
      within:  ['Y', 'No'],
      message: '^Please indicate the drug status'
    },
    presence: {
      allowEmpty: false
    }
  }
};

export function medicationValidator(attributes) {
  return validate(attributes, constraints);
}
