import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { teladocApi, authToken } from '@td/api';
import { getRecordByKey, saveRecord } from '../../lib/persistence';

import PatientVolumeManagerContainer from '../../patient-volume-manager';
import PatientList from '../../patient-list';
import FeatureOverviewPatientLimits from '../../components/NewFeatureOverview/components/featureOverviewPatientLimits';
import Loader from 'app/components/Loader';
import PatientListManageLimits from '../../patient-list/PatientListManageLimits';
import ConsultQueues from '../../consult-queues/components/ConsultQueues';

import useAnyConsultationCompleted from '../hooks/useAnyConsultationCompleted';
import { useData } from 'app/DataProvider';

const currentTabKey = 'patients-dashboard-current-tab-index';

const DashboardContent = () => {
  const consultTab = useRef();
  const patientsTab = useRef();
  const [tabIndex, setTabIndex] = useState(Number(getRecordByKey(currentTabKey)) || 0);
  const [isOpen, setIsOpen] = useState(false);

  const { data: anyConsultCompleted, loading: anyConsultationCompletedLoading } = useAnyConsultationCompleted();

  const {
    displayProviderMemberAvailability,
    providerId,
    role,
    maxOverallPatientsLimit,
    maxDailyNewPatientsLimit,
  } = useData();

  const showLegacyConsultQueues = () => {
    const consultsContent = document.getElementById('requested_consult_queue');
    consultTab.current.appendChild(consultsContent);
    consultsContent.style.display = 'initial';
  };

  useEffect(() => {
    if (!displayProviderMemberAvailability) showLegacyConsultQueues();

    teladocApi
      .get('v4/ref_alerts/mvp_guided_alert', authToken.get())
      .then(response => {
        const data = response.data.ref_alert;
        if (data) {
          setIsOpen(data.display_alert);
        }
      })
      .catch(() => {
        setIsOpen(false);
      });
  }, []);

  return (
    <React.Fragment>
      <div id="summaryCardsContainer" />
      <div className="dashboard-content">
        <div className="tabs-buttons">
          <div className="tabs-container">
            <button
              className={classNames({ active: tabIndex === 0 })}
              onClick={() => {
                saveRecord(currentTabKey, 0);
                setTabIndex(0);
              }}
            >
              <span className="button-text">{I18n.t('dashboard.tabs.visits')}</span>
              {tabIndex === 0 && <span className="button-active-bar" />}
            </button>
            <button
              className={classNames({ active: tabIndex === 1 })}
              onClick={() => {
                saveRecord(currentTabKey, 1);
                setTabIndex(1);
              }}
            >
              <span className="button-text">{I18n.t('dashboard.tabs.patients')}</span>
              {tabIndex === 1 && <span className="button-active-bar" />}
            </button>
          </div>
          <PatientVolumeManagerContainer
            providerId={providerId}
            role={role}
            maxOverallPatientsLimit={maxOverallPatientsLimit}
            maxDailyNewPatientsLimit={maxDailyNewPatientsLimit}
            isAnyConsultCompleted={anyConsultCompleted}
          />
        </div>
        <div className="tabs-panels">
          <section id="tab-consults" ref={consultTab} className={classNames('tabs-panel', { active: tabIndex === 0 })}>
            {displayProviderMemberAvailability && <ConsultQueues />}
          </section>
          <section id="tab-patients" ref={patientsTab} className={classNames('tabs-panel', { active: tabIndex === 1 })}>
            {anyConsultationCompletedLoading ? (
              <div className="any-consultation-completed-loading">
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                {anyConsultCompleted ? (
                  <PatientList />
                ) : (
                  <div className="patients-tab-container">
                    <div className="managePatientListBtnContainer">
                      <PatientListManageLimits />
                    </div>
                    <p>{I18n.t('patient_case_load_mgmt_mvp.patient_list.no_completed_consultation')}</p>
                  </div>
                )}
              </React.Fragment>
            )}
          </section>
        </div>
      </div>
      <FeatureOverviewPatientLimits isOpen={isOpen} setIsOpen={setIsOpen} />
    </React.Fragment>
  );
};

export default DashboardContent;
