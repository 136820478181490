import React, { useState } from 'react';
import { translate } from '@td/shared_utils';
import TeladocModal from '../../TeladocModal';

const TRANSLATION_SCOPE = 'dashboard.availability_check_modal';

const AvailabilityCheckModal = () => {
  /*
   * State
   */
  const [showModal, setShowModal] = useState(true);

  /*
   * Callbacks
   */
  const onConfirm = () => {
    setShowModal(false);
    window.location.assign('/calendar');
  };

  /*
   * Render
   */
  return (
    <div className="availabilityCheckModalWrapper">
      <TeladocModal
        className="availabilityCheckModal"
        title={translate(null, TRANSLATION_SCOPE, 'title')}
        isOpen={showModal}
        showCloseButton
        onClose={() => setShowModal(false)}
      >
        <div className="content">
          <div className="explanation">
            <p>{translate(null, TRANSLATION_SCOPE, 'explanation_part1')}</p>
            <p>{translate(null, TRANSLATION_SCOPE, 'explanation_part2')}</p>
          </div>
          <div className="availabilityCheckModalButtons">
            <button id="availabilityCheckModalConfirm" className="button" onClick={onConfirm}>
              {translate(null, TRANSLATION_SCOPE, 'confirm_button')}
            </button>
          </div>
        </div>
      </TeladocModal>
    </div>
  );
};

export default AvailabilityCheckModal;
