import React from 'react';
import Select, { components } from 'react-select';

const InputOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
  const style = {
    alignItems:      'center',
    backgroundColor: 'transparent',
    color:           'inherit',
    display:         'flex ',
    gap:             '8px'
  };

  const props = {
    ...innerProps,
    style
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

export const CustomSelect = ({ placeholder, field, form, options, isMulti = false }) => {
  const onChange = option => {
    form.setFieldValue(field.name, isMulti ? option.map(item => item.value) : option.value);
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(option => field.value.indexOf(option.value) >= 0)
        : options.find(option => option.value === field.value);
    } else {
      return isMulti ? [] : '';
    }
  };

  const customStyles = {
    control: baseStyles => ({
      ...baseStyles,
      width:        '100%',
      border:       '1px solid #cccccc',
      borderRadius: '0'
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: 'white',
      border:          '1px solid #CCCCCC',
      borderRadius:    '4px',
      height:          '24px'
    }),
    multiValueLabel: styles => ({
      ...styles,
      color:      '#444443',
      fontSize:   '12px',
      display:    'flex',
      alignItems: 'center'
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: '#444443'
    })
  };

  return (
    <Select
      styles={customStyles}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      hideSelectedOptions={false}
      components={{
        Option: InputOption
      }}
    />
  );
};

export default CustomSelect;
