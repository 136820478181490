import React from 'react';
import DataTable from '../data-table';
import PatientListStatus from './PatientListStatus';
import PatientListDiagnosis from './PatientListDiagnosis';
import { usePatientList } from './PatientListProvider';
import { getBrowserTimezone } from './utils';
import { isEmpty } from 'lodash';

const columns = [
  {
    id:       'personName',
    name:     I18n.t('patient_case_load_mgmt_mvp.patient_list.name'),
    selector: ({ fullName }) => <p>{fullName}</p>,
    sortable: true
  },
  {
    id:       'status',
    name:     I18n.t('patient_case_load_mgmt_mvp.patient_list.status'),
    selector: ({ relationWithCurrentProvider }) => {
      const { statusCd } = relationWithCurrentProvider || {};
      return <PatientListStatus statusCd={statusCd} />;
    },
    sortable: true
  },
  {
    id:       'diagnosis',
    name:     I18n.t('patient_case_load_mgmt_mvp.patient_list.primary_diagnosis'),
    selector: ({ lastConsultationForCurrentProvider }) => {
      const { clinicalDiagnosis } = lastConsultationForCurrentProvider || {};
      return <PatientListDiagnosis clinicalDiagnosis={clinicalDiagnosis} />;
    }
  },
  {
    id:       'last_consultation',
    name:     I18n.t('patient_case_load_mgmt_mvp.patient_list.last_consult'),
    selector: ({ lastConsultationForCurrentProvider }) => {
      const { actualEndDate } = lastConsultationForCurrentProvider || {};
      return getBrowserTimezone(actualEndDate);
    },
    sortable: true
  },
  {
    id:       'next_consultation',
    name:     I18n.t('patient_case_load_mgmt_mvp.patient_list.next_consult'),
    selector: ({ nextConsultationForCurrentProvider }) => {
      const { requestOrFollowUpDate } = nextConsultationForCurrentProvider || {};
      return getBrowserTimezone(requestOrFollowUpDate);
    },
    sortable: true
  }
];

const PatientList = () => {
  const {
    data: { collection = [] } = {},
    loading,
    sortColumn,
    sortDirection,
    handleSortChange,
    filters
  } = usePatientList();

  const noFilterMatches = !loading && !collection.length && !isEmpty(filters);

  return noFilterMatches ? (
    <p>{I18n.t('patient_case_load_mgmt_mvp.patient_list.filters.no_matches')}</p>
  ) : (
    <DataTable
      columns={columns}
      data={collection}
      loading={loading}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      onSort={handleSortChange}
    />
  );
};

export default PatientList;
