export const DEFAULT_BRAND = 'Teladoc';

export const CONSULTS_THAT_CAN_REFER_TO_ITSELF = ['Mental Health', 'Primary Care', 'Nutrition'];

export const NON_TELADOC_SERVICES = {
  ALTCARE_PCP:        'ALTCARE_PCP',
  ALTCARE_SPECIALIST: 'ALTCARE_SPECIALIST',
  ALTCARE_SH:         'ALTCARE_SH',
  ALTCARE_SHEALTH:    'ALTCARE_SHEALTH'
};

export const NON_TELADOC_SERVICES_THAT_SUPPORT_PCP_REFERAL = [
  NON_TELADOC_SERVICES.ALTCARE_PCP,
  NON_TELADOC_SERVICES.ALTCARE_SPECIALIST
];

export const FOLLOWUP_APPOINTMENT_OPTIONS = {
  PROVIDER_SCHEDULED: 'with_followup_date',
  MEMBER_SCHEDULED:   'member_scheduled_appointment',
  NO_APPOINTMENT:     'with_days',
  ADDITIONAL_CARE:    'additional_care'
};

export const FOLLOWUP_SECTIONS = {
  NO_APPOINTMENT:       'NO_APPOINTMENT',
  TELADOC_SERVICES:     'TELADOC_SERVICES',
  NON_TELADOC_SERVICES: 'NON_TELADOC_SERVICES',
  P2P_NOTE:             'P2P_NOTE'
};

export const SERVICE_PLATFORM_CODES = {
  Teladoc: 'SERVICEPLATFORM_COMM',
  EMS:     'SERVICEPLATFORM_EXPERT'
};
