import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AcceptingNewPatientsSwitcher from './components/accepting-new-patients-switcher';
import PatientsLimitModal from './components/patients-limit-modal';
import SummaryCardsContainer from './components/patient-summary-card/SummaryCardsContainer';
import NotificationsContainer from './components/patient-limit-configure-notification/NotificationsContainer';


const PatientVolumeManager = ({
  modal,
  toggle,
  summaryData
}) => {

  const [showNotification, setShowNotification] = useState(false);

  return (
    <React.Fragment>
      <div className="patient-volume-manager-container">
        <React.Fragment>
          <NotificationsContainer 
            modalData={modal.modalData}
            showNotification={showNotification}
            setShowNotification={setShowNotification}
          />
          <SummaryCardsContainer
            summaryData={{
              ...summaryData,
              overallPatientsLimit: modal.modalData.overallPatientsLimit
            }}
          />
          <AcceptingNewPatientsSwitcher
            toggle={toggle}
            position={'out'}
          />
          <PatientsLimitModal
            modal={modal}
            toggle={toggle}
            setShowNotification={setShowNotification}
          />
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

PatientVolumeManager.propTypes = {
  toggle: PropTypes.shape({
    toggleData: PropTypes.shape({
      acceptingNewPatients:      PropTypes.bool,
      tooltipMessage:            PropTypes.array,
    }),
    updateToggleValue: PropTypes.func
  }),
  modal: PropTypes.shape({
    modalData: PropTypes.shape({
      role:                      PropTypes.string,
      providerId:                PropTypes.number,
      displayModal:              PropTypes.bool,
      defaultOverall:            PropTypes.number,
      defaultDaily:              PropTypes.number,
      overallPatientsLimit:      PropTypes.number,
      dailyNewPatientLimit:      PropTypes.number,
      practiceTeamParticipantId: PropTypes.string,
      practiceTeamId:            PropTypes.string,
      currentPatients:           PropTypes.number
    }),
    setModalData: PropTypes.func
  }),
  summaryData: PropTypes.shape({
    todayNewPatients:            PropTypes.number,
    todayEstablishedPatients:    PropTypes.number,
    currentPatients:             PropTypes.number,
    isAnyConsultCompleted:       PropTypes.bool
  })
};

export default PatientVolumeManager;
