import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { Toggle } from 'medication_service_ui';
import { isEmpty } from 'lodash';
import TextInput from 'app/components/TextInput';
import SelectInput from './SelectInput';
import RemedyApiStatusWarning from './RemedyApiStatusWarning';
import { medicationValidator } from '../validators';
import { units } from '../units';

const getSuggestionValue = suggestion => suggestion.name;

const renderSuggestion = suggestion => suggestion.name;

class ClinicalMedicationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remedyApiStatus:   this.props.remedyApiStatus || false,
      isSaving:          false,
      errors:            {},
      medication:        this.props.medication || {},
      selectedMed:       {},
      value:             '',
      toggleIsCollapsed: true
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.submitResult !== nextProps.submitResult) {
      this.resetForm();
    }

    if (this.props.medication !== nextProps.medication) {
      const {
        medication,
        medication: { drugName, drugFrequency, drugQuantity }
      } = nextProps;

      if (!isEmpty(drugFrequency) && !isEmpty(drugQuantity)) {
        this.setState({
          medication,
          selectedMed: {},
          value:       drugName
        });
      }
    }
  }

  onSuggestionsClearRequested = () => {
    this.props.resetMedicationList();
  };

  onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();

    this.setState({ selectedMed: suggestion });
    this.props.setSelectedMedication(suggestion);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.searchMedication(value);
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.props.suggestions.filter(med => med.name.toLowerCase().slice(0, inputLength) === inputValue);
  };

  searchMedication = value => {
    const { remedyApiStatus } = this.props;

    if (remedyApiStatus && value.length > 1) {
      this.props.fetchMedicationList(value);
    }
  };

  handleOnCancel = evt => {
    evt.preventDefault();
    const { medication } = this.state;

    this.setState({
      isSaving:          !this.state.isSaving,
      selectedMed:       {},
      value:             '',
      errors:            {},
      toggleIsCollapsed: true,
      medication:        {
        ...medication,
        drugQuantity:       '',
        drugQuantityUnit:   '',
        drugFrequency:      '',
        activelyTakingFlag: '',
        drugCode:           ''
      }
    });
  };

  handleOnChange = event => {
    const { medication } = this.state;
    const field = event.target.name;

    this.setState({
      medication: { ...medication, [field]: event.target.value }
    });
  };

  resetForm = () => {
    const { medication } = this.state;

    this.setState({
      selectedMed: {},
      value:       '',
      errors:      {},
      medication:  {
        ...medication,
        drugQuantity:       '',
        drugQuantityUnit:   '',
        drugFrequency:      '',
        drugCode:           '',
        activelyTakingFlag: ''
      }
    });
  };

  validateFormData(medication) {
    let isValid = true;
    const errors = medicationValidator(medication);

    if (errors !== undefined && Object.keys(errors).length > 0) {
      this.setState({ errors });
      isValid = false;
    }
    return isValid;
  }

  handleOnAddMedication = event => {
    event.preventDefault();

    let { medication } = this.state;
    const { selectedMed, value } = this.state;
    const { drugQuantity, drugQuantityUnit } = medication;

    medication = {
      ...medication,
      drugName:     value,
      name:         value,
      drugCode:     medication.drugCode || selectedMed.code || selectedMed.id,
      drugTypeCode: selectedMed.code_type,
      drugQuantity,
      drugQuantityUnit
    };

    if (!this.validateFormData(medication)) {
      return false;
    }

    this.setState({
      isSaving: !this.state.isSaving
    });

    this.props.addToPendingMedicationList(medication);
    this.resetForm();
  };

  render() {
    const { isSaving, suggestions, remedyApiStatus } = this.props;
    const { errors, medication, value, toggleIsCollapsed } = this.state;

    const inputProps = {
      value,
      onChange:    this.onChange,
      placeholder: 'Enter name to start searching drug'
    };

    return (
      <div className="clinical-medication__form-wrapper">
        <div className="wrapper">
          {!remedyApiStatus && <RemedyApiStatusWarning />}

          <Toggle
            header="Add additional Patient Identified Medications"
            onToggle={collapsed => this.setState({ toggleIsCollapsed: collapsed })}
            collapsed={toggleIsCollapsed}
            data-test-id="medicationToggle"
          >
            <p>Instruction to provider: Enter additional medications patient has indicated they are taking</p>
            <form className="clinical-medication__form-wrapper--form" onSubmit={this.handleOnSubmit}>
              <fieldset>
                <div className="field-group">
                  <label className="label" htmlFor="drugName">
                    Medication
                  </label>

                  <div className="field">
                    <Autosuggest
                      suggestions={suggestions}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      onSuggestionSelected={this.onSuggestionSelected}
                      inputProps={inputProps}
                    />
                    {errors.drugName && <div className="error-msg">{errors.drugName[0]}</div>}
                  </div>
                </div>

                <div className="field-group">
                  <div className="flex-1">
                    <TextInput
                      name="drugQuantity"
                      label="Dosage"
                      onChange={this.handleOnChange}
                      value={medication.drugQuantity}
                      error={errors.drugQuantity}
                    />
                  </div>

                  <div className="flex-1">
                    <SelectInput
                      hiddenLabel
                      name="drugQuantityUnit"
                      value={medication.drugQuantityUnit}
                      defaultOption="Select unit..."
                      options={units}
                      onChange={this.handleOnChange}
                      label={null}
                      error={errors.drugQuantityUnit}
                    />
                  </div>
                </div>

                <SelectInput
                  name="drugFrequency"
                  label="Directions"
                  value={medication.drugFrequency}
                  defaultOption="Select one..."
                  options={this.props.directionList}
                  onChange={this.handleOnChange}
                  error={errors.drugFrequency}
                />
                <SelectInput
                  name="activelyTakingFlag"
                  label="Status"
                  value={medication.activelyTakingFlag}
                  defaultOption="Select one..."
                  options={this.props.statusList}
                  onChange={this.handleOnChange}
                  error={errors.activelyTakingFlag}
                />
                <div className="field-group">
                  <div className="field">
                    <input
                      type="submit"
                      disabled={isSaving}
                      value="Cancel"
                      className="btn btn-primary"
                      onClick={this.handleOnCancel}
                    />
                  </div>
                  <div className="field">
                    <input
                      type="submit"
                      disabled={isSaving}
                      value={isSaving ? 'Adding to Pending List' : 'Add Medication'}
                      className="btn btn-primary"
                      onClick={this.handleOnAddMedication}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </Toggle>
        </div>
      </div>
    );
  }
}

ClinicalMedicationForm.defaultProps = {
  errors:     {},
  statusList: [
    { value: 'Y', text: 'Current' },
    { value: 'No', text: 'Past' }
  ],
  directionList: [
    { value: 'Daily', text: 'Daily' },
    { value: 'Twice a day', text: 'Twice a day' },
    { value: 'Three times a day', text: 'Three times a day' },
    { value: 'Four times a day', text: 'Four times a day' },
    { value: 'At Bed time', text: 'At Bed time' },
    { value: 'As needed', text: 'As needed' }
  ],
  medication: {},
  isSaving:   false
};

ClinicalMedicationForm.propTypes = {
  value:           PropTypes.string,
  isSaving:        PropTypes.bool,
  remedyApiStatus: PropTypes.bool.isRequired,
  erxPatientId:    PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  memberParams:    PropTypes.shape({
    memberId:       PropTypes.number.isRequired,
    memberDoB:      PropTypes.string.isRequired,
    erxPatientId:   PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    consultationId: PropTypes.number
  }),
  isLoading:                  PropTypes.bool.isRequired,
  fetched:                    PropTypes.bool.isRequired,
  directionList:              PropTypes.array.isRequired,
  statusList:                 PropTypes.array.isRequired,
  suggestions:                PropTypes.array.isRequired,
  pendingMedications:         PropTypes.array.isRequired,
  selectedMed:                PropTypes.object,
  errors:                     PropTypes.object,
  submitResult:               PropTypes.object,
  medication:                 PropTypes.object.isRequired,
  fetchMedicationList:        PropTypes.func.isRequired,
  setSelectedMedication:      PropTypes.func.isRequired,
  resetMedicationList:        PropTypes.func.isRequired,
  addToPendingMedicationList: PropTypes.func.isRequired,
  clearPendingMedicationList: PropTypes.func.isRequired,
  fetchClinicalMedications:   PropTypes.func.isRequired
};

export default ClinicalMedicationForm;
