// Important: This function initializes both the EHR Preview and the main page (consultations#show).
// When something new is added here, please use selectors that find via the rootSelector.
// Example $(rootSelector).find(...)
// The root is available as rootNode (node item), $rootNode (jquery)
// That ensures that selectors are scoped (function can run on any DOM container).
// Your component will be rendered in the EHR Preview, if there are editing controls, they have to be disabled.

import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { isUndefined, get, isNaN, keys } from 'lodash';

import notificationsListener from 'app/lib/notifications-listener';
import VideoInitializer from 'app/Video/components/video-initializer';
import VideoTestContainer from '@td/video_test';
import { CarePlanShow } from '@td/care_plan';
import LabPrescription from 'app/LabPrescription';
import Conference from 'app/Conference/components';
import CareCoordinatorTransfer from 'app/Consult/Followup/components/CareCoordinatorTransfer';
import { Container as Benefits } from 'medication_service_ui/dist/benefits.js';
import { actions as settingsActions } from 'medication_service_ui/dist/settings';
import { initializeAutoDialer } from 'app/AutoDialer/initializer';
import ConsultNavigation from 'app/consult-navigation';
import MedicationReview from 'app/medication-review';
import {
  PrescriptionModal,
  ClinicalAllergy,
  ClinicalAllergyActive,
  ActivePrescriptionModalContainer as ActivePrescriptionModal,
  readCachedMedications,
  MedicationHistory
} from 'medication_service_ui';
import 'medication_service_ui/dist/benefits.css';
import providerActiveStatus from 'app/providerActiveStatus/index';
import PersonalHealthRecords from './PersonalHealthRecords/index';
import ClinicalMedication from './ClinicalMedication';
import ProviderOrder from './ProviderOrder';
import Scratchpad from './scratchpad';
import SubjectiveTab from './subjective-tab';
import ErxErrors from './erx-errors';
import NetworkTest from './network-test';
import NutritionPlan from './nutrition-plan';
import ProviderClockInOut from './sidebar/provider-clock-in-out';
import SubmitConsult from './submit-consult';
import QuickLinks from './sidebar/QuickLinks';
import ConsultHistoryPageContainer from './consult-history/components/consult-history-page-container';
import AvailabilityReminder from './dashboard/availability-reminder';
import InactivityNotifications from './dashboard/inactivity-notifications';
import { ProviderPrompts, ProviderSolicitation } from './study-prompts';
import NetPromoterScore from 'app/NetPromoterScore/components/net-promoter-score.js';
import Counter from './counter';
import DashboardContent from './dashboard-content';
import { AvailabilityCheckContainer } from './availability-check';
import { MyScheduleContainer } from './my-schedule';
import ProviderCalendar from './calendar/provider-calendar';

import { initialize as initializeFavIconManager } from 'app/lib/favicon-manager';
import Alert from './components/alert';
import { showAlert } from './components/alert/actions';
import { COMPONENT_ID as PROPOSE_PHONE_ONLY_MESSAGE_ID } from './Provider/components/alerts/propose-phone-only-message';

import store from './lib/store';
import render from './lib/render';
import { fetchQuickRxByProvider, addToPendingPrescriptionList } from './Prescription/actions';
import { trackConsultInactivity, trackForClockOut } from './Provider/actions';
import { setClockInOutSettings } from './sidebar/provider-clock-in-out/actions';
import {
  initConsultationQueueRefresh
} from './dashboard/consultation-queue/actions/consultation-queue-actions';
import { updateNetworkTestSettings } from './network-test/actions';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloProviderNew } from '@apollo/client';
import { CanadaPrescriptionContainer } from 'app/canada/prescription';
import CanadaProviderOrderContainer from 'app/canada/provider-order';
import { HistoryProviderOrder } from 'app/canada/provider-order';
import CanadaPharmacyContainer from 'app/canada/pharmacy';
import getClientInstance from 'app/services/apollo-client';
import preventProfileEdit from 'app/canada/provider-profile';
import { EhrFileUploadsContainer } from 'app/FileUploads/components/ehr-file-uploads-container';
import { PrxFileUploadsContainer } from 'app/FileUploads/components/prx-file-uploads-container';
import AvailabilityNotification from './availability-notification';
import { LockNextConsultRequestHandler } from 'app/lock-next-consult';
import { PrimaryCareSummaryButton, AllscriptPrimaryCareSummaryButton } from '@td/emr';
import SecondaryDiagnosesContainer from 'app/components/SecondaryDiagnosesContainer';
import SummarySecondaryDiagnoses from 'app/components/SecondaryDiagnosesContainer/components/SummarySecondaryDiagnoses';
import EHR from 'app/ehr';
import { EHRNavigationButtons } from 'app/ehr';
import ClinicalAlerts from 'app/clinical-alerts/components/clinical-alerts-container';
import ButtonElationEhr from 'app/components/ButtonElationEhr/ButtonElationEhr';
import ButtonSunriseEhr from 'app/components/ButtonSunriseEhr/ButtonSunriseEhr';
import {
  initializeConsultFollowup,
  initializeInfoboxSpecialties,
  initializeConsultFollowupTitle
} from './consult-followup';
import ConsultationDiagnosisShortcuts from 'app/components/ConsultationDiagnosisShortcuts';
import ConsultationReschedulingModal from './consultation-rescheduling/components/consultation-rescheduling-modal';
import PopupForSubjectiveTab from 'app/components/PopupForSubjectiveTab';
import { OutdatedBrowserVersionNotice } from 'app/components/OutdatedBrowserVersionNotice';
import { ProviderConsultationTimer } from '../app/provider-consultation-timer';
import { wasEhrTabReviewedBefore } from './ehr/ehr-utils';
import { Inbox } from './inbox';

import { initializeQuickDx } from 'app/QuickDx';

import { ElationVisitNotes, AllscriptVisitNotes, AllscriptClinicalIntakeNotes } from '@td/emr';
import MultiWayConsultAttendeesVerification from './ehr/components/MultiWayConsult/multi-way-consult-attendees-verification';
import NotificationPreferences from '@td/notification_preferences';

import { mapKeysDeep, transformKey } from '@td/utils';
import PsychosocialStressors from './subjective-tab/components/psychosocial-stressors/psychosocial-stressors';
import { camelizeDeep } from './lib/utils';
import MentalStatusExam from './components/objective-tab/mental-status-exam/mental-status-exam';
import AddAddendum from './consult-history/components/add-addendum';
import PatientVolumeManagerContainer from './patient-volume-manager';

import { RecommendedWorkingHoursProvider } from './recommended-working-hours/contexts/recommended-working-hours-context';

import getProviderParams from './Provider/selectors/getProviderParams';
import getProviderClockedIn from './Provider/selectors/getProviderClockedIn';

import { DataProvider } from './DataProvider';
import HealthCardContainer from 'app/canada/health-card/container';

const {
  updateDrugSearchAvailability,
  updateConsultationParams,
  updateRxSubmissionTimingParams,
  updateProviderParams,
  updateMemberParams,
  updatePharmacyParams,
  updatePatientSyncStatus,
  updateQuickRxParams,
  updateCountryCode
} = settingsActions;

export default (rootSelector = 'body', { editable = false, props = {} } = {}) => {
  const rootNode = document.querySelector(rootSelector);
  const $rootNode = $(rootNode);

  const videoParams = $rootNode.find('#react_video_widget_container').data('videoParams');
  const canAutoCall = $rootNode.find('#react_video_widget_container').data('canAutoCall');
  const countryCd = $rootNode.find('#consult_form').data('countryCode');
  const submitRxOnCompletion = $rootNode.find('#consult_form').data('submit-rx-on-consult-completion');
  const conferenceElement = window.document.getElementById('conference');
  const scratchpadText = $('#react_scratchpad_container').data('scratchpad-text');

  const sharedClientInstance = getClientInstance();

  const onVideoExpanded = isExpanded => {
    $rootNode.toggleClass('video_expanded', isExpanded);
  };

  const updateScratchpadFormField = text => {
    $('#interview_scratchpad').val(text);
  };

  const consultationIsGlobal = $('#consult_form #consult').data('consult_is_global');

  // Temporary fix for Canada Provider
  // Prevent providers from changing password in provider.teladoc.ca
  preventProfileEdit();

  initializeQuickDx();

  if (rootNode.contains(conferenceElement)) {
    const brandName = conferenceElement.getAttribute('data-brand-name');
    const consultationId = conferenceElement.getAttribute('data-consultation-id');
    const providerId = conferenceElement.getAttribute('data-provider-id');
    const showCallRecordingMessage = conferenceElement.getAttribute('data-show-call-recording-message');
    render(Conference, '#conference', { brandName, consultationId, providerId, showCallRecordingMessage }, false);
    render(CareCoordinatorTransfer, '#care-coordinator-transfer', { brandName, consultationId }, false);
  }

  const videoWidgetContainer = $('#react_video_widget_container');
  const consultationId = videoWidgetContainer.data('consultation-id');
  const consultation = mapKeysDeep(videoWidgetContainer.data('consultation'), transformKey);
  const attendeesNotificationsEnabled = videoWidgetContainer.data('attendees-notifications-enabled');
  render(Scratchpad, '#react_scratchpad_container', { scratchpadText, updateScratchpadFormField });
  render(VideoInitializer, '#react_video_widget_container', {
    videoParams,
    consultationId,
    onVideoExpanded,
    canAutoCall,
    attendeesNotificationsEnabled,
    consultation
  });
  render(Counter, '#unread-indicator');
  render(Inbox, '#active_conversations_container');
  render(Inbox, '#emergency-assistance', {
    emergencyAssistance: true,
    countryCd,
    containerId:         'emergency-assistance'
  });
  initializeAutoDialer({ $rootNode });
  const notificationsInformationContainer = document.querySelector('#notifications_information_section');
  if (notificationsInformationContainer) {
    const actor = JSON.parse(notificationsInformationContainer.dataset.actor);
    ReactDOM.render(
      <ApolloProviderNew client={sharedClientInstance}>
        <NotificationPreferences actor={actor} />
      </ApolloProviderNew>,
      document.querySelector('#notifications_information_section')
    );
  }
  render(OutdatedBrowserVersionNotice, '#outdated_browser_version_notice');

  // Video Test
  const el = $('#video-test-container');
  const androidAppLink = el.data('android-app-link');
  const brandName = el.data('brand-name');
  const iosAppLink = el.data('ios-app-link');
  const tollfreeNumber = el.data('toll-free-number');
  const videoFailureMessage = el.data('video-failure-message');

  render(VideoTestContainer, '#video-test-container', {
    androidAppLink,
    brandName,
    iosAppLink,
    tollfreeNumber,
    videoFailureMessage
  });

  const ehrNavigationButtonsSelector = document.querySelector('#react_ehr_navigation_buttons');
  if (ehrNavigationButtonsSelector) {
    const consultationId = parseInt(ehrNavigationButtonsSelector.dataset.consultationId);
    const shouldDisplayAllscriptCheckboxes = ehrNavigationButtonsSelector.dataset.shouldDisplayAllscriptCheckboxes;

    render(EHRNavigationButtons, '#react_ehr_navigation_buttons', { consultationId, shouldDisplayAllscriptCheckboxes });
  }

  const consultationDiagnosisShortcuts = document.querySelector('#consultation-diagnosis-shortcuts');
  if (consultationDiagnosisShortcuts) {
    ReactDOM.render(
      <ApolloProviderNew client={getClientInstance()}>
        <ConsultationDiagnosisShortcuts
          consultationId={parseInt(consultationDiagnosisShortcuts.dataset.consultationId)}
        />
      </ApolloProviderNew>,
      $(`${rootSelector} #consultation-diagnosis-shortcuts`).get(0)
    );
  }

  const elationVisitNotes = document.querySelector('#elation_visit_notes');
  if (elationVisitNotes) {
    const consultationId = parseInt(elationVisitNotes.dataset.consultationId);
    const pastConsult = elationVisitNotes.dataset.pastConsult;

    render(ElationVisitNotes, '#elation_visit_notes', { consultationId, pastConsult });
  }

  const allscriptVisitNotes = document.querySelector('#allscript_visit_notes');
  if (allscriptVisitNotes) {
    const consultationId = parseInt(allscriptVisitNotes.dataset.consultationId);
    const pastConsult = allscriptVisitNotes.dataset.pastConsult;

    if (window.allscript_api_enabled_active) {
      render(AllscriptVisitNotes, '#allscript_visit_notes', { consultationId, pastConsult });
    }
  }

  const allscriptIntakeNotes = document.querySelector('#allscript_clinical_intake_notes');
  if (allscriptIntakeNotes) {
    const consultationId = parseInt(allscriptIntakeNotes.dataset.consultationId);
    const pastConsult = elationVisitNotes.dataset.pastConsult;
    render(AllscriptClinicalIntakeNotes, '#allscript_clinical_intake_notes', { consultationId, pastConsult });
  }

  const elationEhrButtons = document.querySelectorAll('#button-elation-ehr');

  $(elationEhrButtons).each((_index, button) => {
    const consultationId = parseInt($(button).data('consultationId'));

    if (isNaN(consultationId)) return;

    ReactDOM.render(
      <ApolloProviderNew client={getClientInstance()}>
        <ButtonElationEhr consultationId={consultationId} />
      </ApolloProviderNew>,
      button
    );
  });

  const sunriseEhrButtons = document.querySelectorAll('#button-sunrise-ehr');

  $(sunriseEhrButtons).each((_index, button) => {
    const consultationId = parseInt($(button).data('consultationId'));

    if (isNaN(consultationId)) return;

    ReactDOM.render(
      <ApolloProviderNew client={getClientInstance()}>
        <ButtonSunriseEhr consultationId={consultationId} />
      </ApolloProviderNew>,
      button
    );
  });

  if (document.getElementById('personal_health_records')) {
    const memberId = $('#personal_health_documents').data('memberId');
    render(PersonalHealthRecords, '#personal_health_documents', { memberId }, false);
  }

  if (window.allscript_api_enabled_active) {
    $('.allscript-primary-care-summary-button').each((_index, button) => {
      const consultationId = $(button).data('consultationId');

      ReactDOM.render(<AllscriptPrimaryCareSummaryButton consultationId={consultationId} />, button);
    });
  }

  if (window.FEATURE_TOGGLES.elation_api_enabled) {
    $('.primary-care-summary-button').each((_index, button) => {
      const consultationId = $(button).data('consultationId');

      ReactDOM.render(<PrimaryCareSummaryButton consultationId={consultationId} />, button);
    });
  }

  const secondaryDiagnoses = document.querySelector('#secondary_diagnoses');
  if (secondaryDiagnoses) {
    const cachedSecondaryDiagnoses = secondaryDiagnoses.dataset.cachedSecondaryDiagnoses;

    render(SecondaryDiagnosesContainer, '#secondary_diagnoses', {
      cachedSecondaryDiagnoses: JSON.parse(cachedSecondaryDiagnoses),
      componentName:            'secondary_diagnoses',
      forTabs:                  ['dx', 'emr_assessment']
    });
  }

  const summarySecondaryDiagnoses = document.querySelector('#summary_secondary_diagnosis');
  if (summarySecondaryDiagnoses && window.FEATURE_TOGGLES.vpcc_risk_adjustment_v1) {
    render(SummarySecondaryDiagnoses, '#summary_secondary_diagnosis');
  }

  if (document.getElementById('consultation-dataset-wrapper')) {
    const authorizationToken = $('meta[name=csrf-token]').attr('content');
    const newMedActiveConsult = $rootNode.find('#new-medication-form').data('newMedActiveConsult');
    const newMedicationMhdQuestionId = $rootNode.find('#new-medication-form').data('newMedMhdQuestionId');
    const newMedicationMhdAnswerId = $rootNode.find('#new-medication-form').data('newMedMhdAnswerIds');
    const heightWeightMhdQuestions = $rootNode.find('#new-medication-form').data('heightWeightMhdQuestions');

    // Read data attributes from DOM that are passed to REACT components
    const {
      pharmacy,
      consultation: {
        consultation_id: consultationId,
        consultation_state: stateCode,
        specialty_name: consultSpecialtyName,
        pharmacy_state_unrestricted: pharmacyStateUnrestricted,
        extended_pharmacy_state_search: extendedPharmacyStateSearch,
        country_code: countryCode,
        status_code: statusCode,
        phone_number: phoneNumber,
        request_reason: requestReason,
        started
      },
      member: {
        member_id: memberId,
        member_dob: memberDoB,
        erx_patient_id: erxPatientId,
        member_name: memberName,
        gender: memberGender,
        address: memberAddress,
        height: memberHeight,
        weight: memberWeight
      },
      provider: {
        provider_dea: providerDEA,
        provider_npi: providerNPI,
        provider_can_prescribe: providerCanRx,
        license: providerLicense,
        can_do_cancel_rx: canDoCancelRx,
        provider_first_nm: firstNm,
        provider_last_nm: lastNm,
        provider_address: providerAddress,
        provider_name: providerName,
        provider_name_with_credentials: providerNameWithCredentials,
        eligible_for_med_history: eligibleForMedHistory
      },
      skipPatientSync
    } = $rootNode.find('#consultation-dataset-wrapper').data();

    const remedyApiStatus = countryCode === 'USA' ? window.useRemedyApi : true;
    window.remedyApiStatus = remedyApiStatus;

    const {
      state,
      license,
      expiration_date: expirationDate,
      valid_for_erx: validForErx = false,
      erx_user_id: erxUserId,
      erx_user_token: erxUserToken
    } = providerLicense || {};

    const memberParams = {
      consultationId,
      memberId,
      memberDoB,
      memberName,
      stateCode,
      erxPatientId,
      heightWeightMhdQuestions,
      memberGender,
      memberAddress,
      memberHeight,
      memberWeight
    };

    const consultationParams = {
      memberId,
      consultationId,
      consultationIsGlobal,
      remedyApiStatus,
      consultSpecialtyName,
      stateCode,
      authorizationToken,
      pharmacyStateUnrestricted,
      extendedPharmacyStateSearch,
      countryCode,
      requestReason,
      statusCode,
      started,
      phoneNumber
    };

    const mhdParams = {
      consultationId,
      remedyApiStatus,
      newMedActiveConsult,
      newMedicationMhdQuestionId,
      newMedicationMhdAnswerId
    };

    const providerParams = {
      consultationId,
      providerDEA,
      providerNPI,
      providerCanRx,
      license,
      expirationDate,
      state,
      validForErx,
      erxUserId,
      erxUserToken,
      canDoCancelRx,
      firstNm,
      lastNm,
      providerAddress,
      providerName,
      providerNameWithCredentials,
      eligibleForMedHistory
    };

    // TODO: Remove when ERX is entirely componentized
    $rootNode.find('.ehr_content #medication-table').hide();
    $rootNode.find('#new-medication-form .new_record_ct').hide();
    $rootNode.find('.ct.dynamic_box.new_prescriptions_ct').hide();
    $rootNode.find('.workflow.rx#objective > p').hide();
    $rootNode.find('#objective > ul.yes_no_radios').hide();
    $rootNode.find('#summary_prescriptions').hide(); // FIXME: ERX-716 (subtask of 393)
    $rootNode.find('#allergies_list').hide();
    $('.section.provider_orders h3').hide();
    $('.section.provider_orders > table#provider_orders_table').hide();
    $('.section.provider_orders > .new_record_ct').hide();

    if (providerCanRx) {
      store.dispatch(fetchQuickRxByProvider({ consultationId }));
      store.dispatch(updateQuickRxParams({ canSave: true, canDelete: true }));
    }

    // Set erx_patient_id for existing coffeescritps code
    // https://github.com/Teladoc/teladoc_framework/blob/master/app/assets/javascripts/shared/prescriptions.js.coffee#L294
    window.erx_patient_id = erxPatientId;

    store.dispatch(updateDrugSearchAvailability(!!remedyApiStatus));
    store.dispatch(updatePatientSyncStatus(erxPatientId));
    store.dispatch(updateConsultationParams(consultationParams));
    store.dispatch(updateRxSubmissionTimingParams(submitRxOnCompletion));
    store.dispatch(updateProviderParams(providerParams));
    store.dispatch(updateMemberParams(memberParams));
    store.dispatch(updatePharmacyParams(pharmacy));
    store.dispatch(updateCountryCode(countryCode));

    readCachedMedications().forEach(medication => store.dispatch(addToPendingPrescriptionList(medication)));

    render(ClinicalMedication, `${rootSelector} #td-medication-history`, {
      memberParams,
      mhdParams,
      providerParams,
      editable
    });

    if (countryCode === 'USA') {
      render(MedicationHistory, `${rootSelector} #vendor-med-history`, {
        memberId,
        consultationId,
        providerParams,
        state: stateCode
      });
    }

    const reactConsultNavDom = document.getElementById('consult_nav');
    if (reactConsultNavDom) {
      const { consultationId: navConsultationId, navigationItems } = reactConsultNavDom.dataset;

      ReactDOM.render(
        <ApolloProviderNew client={getClientInstance()}>
          <Provider store={store}>
            <ConsultNavigation
              consultationId={navConsultationId}
              defaultNavigationItems={JSON.parse(navigationItems)}
            />
          </Provider>
        </ApolloProviderNew>,
        reactConsultNavDom
      );
    }

    if (countryCode === 'CAN') {
      const client = getClientInstance();

      if (document.querySelector('#prescription_container')) {
        if (!window.FEATURE_TOGGLES.uniform_erx_builder) {
          ReactDOM.render(
            <ApolloProvider client={client}>
              <CanadaPrescriptionContainer
                consultationId={consultationId}
                memberParams={memberParams}
                consultationParams={consultationParams}
                providerParams={providerParams}
                pharmacy={pharmacy}
              />
            </ApolloProvider>,
            $(`${rootSelector} #prescription_container`).get(0)
          );

          ReactDOM.render(
            <ApolloProvider client={client}>
              <CanadaProviderOrderContainer consultationId={consultationId} consultationParams={consultationParams} />
            </ApolloProvider>,
            $(`${rootSelector} #react-canada_summary_prescriptions`).get(0)
          );
        }

        ReactDOM.render(
          <ApolloProvider client={client}>
            <CanadaPharmacyContainer
              consultationId={consultationId}
              memberParams={memberParams}
              consultationParams={consultationParams}
              providerParams={providerParams}
              pharmacy={pharmacy}
              client={client}
              showChangeLink
            />
          </ApolloProvider>,
          $(`${rootSelector} #react-ca-pharmacy-container`).get(0)
        );
      }

      if (document.querySelector('#react_history_prescription')) {
        if (!window.FEATURE_TOGGLES.uniform_erx_builder) {
          ReactDOM.render(
            <ApolloProvider client={client}>
              <HistoryProviderOrder
                consultationId={consultationId}
                consultationParams={consultationParams}
                client={client}
              />
            </ApolloProvider>,
            $(`${rootSelector} #react_history_prescription`).get(0)
          );
        }

        ReactDOM.render(
          <ApolloProvider client={client}>
            <CanadaPharmacyContainer
              consultationId={consultationId}
              consultationParams={consultationParams}
              providerParams={providerParams}
              client={client}
              showChangeLink={false}
            />
          </ApolloProvider>,
          $(`${rootSelector} #react_history_prescription_pharmacy`).get(0)
        );
      }

      if (document.querySelector('#react-ca-edit-healthcard-button')) {
        const healthCardButton = document.querySelector('#react-ca-edit-healthcard-button');
        const { health_card, member_id, residence_address } = JSON.parse(healthCardButton.getAttribute('member'));
        const { health_card_flg, health_card_required_flg } = JSON.parse(healthCardButton.getAttribute('group'));
        const { id_formats_by_state, expiry_obligations_by_state } = JSON.parse(healthCardButton.getAttribute('constants'));
        const stateMismatchDisclaimer = healthCardButton.getAttribute('state_mismatch_disclaimer')

        const memberData = {
          healthCardEnabled:        health_card_flg,
          healthCardRequired:       health_card_required_flg,
          healthCardInfo:           health_card,
          memberId:                 member_id,
          idFormatsByState:         id_formats_by_state,
          expiryObligationsByState: expiry_obligations_by_state,
          homeStateProvince:        residence_address['state_province'],
          stateMismatchDisclaimer:  stateMismatchDisclaimer
        };

        ReactDOM.render(
          <ApolloProvider client={client}>
            <HealthCardContainer
              memberParams={memberData}
            />
          </ApolloProvider>,
          healthCardButton
        );
      }
    }

    if (countryCode !== 'CAN' || window.FEATURE_TOGGLES.uniform_erx_builder) {
      render(ActivePrescriptionModal, `${rootSelector} #prescription_container`, {
        providerParams,
        memberParams,
        consultationParams,
        skipPatientSync
      });

      render(ProviderOrder, `${rootSelector} #provider_orders_container`, {
        consultationParams,
        providerParams,
        displayOptions: { section: 'rxView', showActions: false }
      });

      // Summary tab
      // TOOD: displayBlank is a temporay state to display 'None' when there's no
      // prescriptions. Future improvement will be to have this as default state
      // and thus removing the need to maintain state as props.
      render(ProviderOrder, `${rootSelector} #react-summary_prescriptions`, {
        consultationParams,
        displayOptions: { section: 'summary', displayBlank: true, showActions: false }
      });

      // History page (not consultation)
      render(ProviderOrder, `${rootSelector} #react_provider_orders_summary`, {
        consultationParams,
        providerParams,
        displayOptions: { section: 'history', showRequester: true, showActions: true }
      });

      render(PrescriptionModal, '#react_history_prescription', {
        providerParams,
        memberParams,
        consultationParams,
        skipPatientSync
      });
    }

    if (countryCode !== 'CAN') {
      render(Benefits, `${rootSelector} #react-benefits-information`, {});
    }

    const { cachedErxErrors } = $rootNode.find('#react_erx_errors').data() || {};

    render(ErxErrors, `${rootSelector} #react_erx_errors`, {
      consultationId,
      cachedErxErrors
    });

    // Subjective tab
    const { subjectiveDocumentation } = $rootNode.find('#react_subjective_tab_container').data() || {};

    render(SubjectiveTab, `${rootSelector} #react_subjective_tab_container`, {
      subjectiveDocumentation
    });

    if (document.getElementById('popup_for_complaints_tab')) {
      render(PopupForSubjectiveTab, `${rootSelector} #popup_for_complaints_tab`, {});
    }

    if (document.getElementById('react_pui_tab_container')) {
      render(ClinicalAlerts, `${rootSelector} #react_pui_tab_container`, {});
    }

    render(MedicationReview, `${rootSelector} #react__reviews-container`, {});

    render(ClinicalAllergy, `${rootSelector} #react__clinical-allergy-container`, { memberId });

    render(EhrFileUploadsContainer, `${rootSelector} #react__ehr-file-uploads-container`, {
      memberId,
      consultationId,
      memberName
    });
    render(PrxFileUploadsContainer, `${rootSelector} #react__prx-file-uploads-container`, {
      memberId,
      consultationId,
      memberName
    });
    render(PrxFileUploadsContainer, `${rootSelector} #react__prx-followup-file-uploads-container`, {
      memberId,
      consultationId,
      memberName
    });
    if (document.getElementById('react_submission_request_handler')) {
      render(LockNextConsultRequestHandler, `${rootSelector} #react_submission_request_handler`, { consultationId });
    }

    // Nutrition Plan
    const { nutritionPlan, patientInfo } = $rootNode.find('#react__nutrition-plan-container').data() || {};

    render(NutritionPlan, `${rootSelector} #react__nutrition-plan-container`, {
      nutritionPlan,
      patientInfo
    });

    render(ClinicalAllergyActive, `${rootSelector} #react__clinical-allergy-active-container`, {
      displayStatus: false
    });
  }

  if (document.getElementById('lab_order_prescription')) {
    const { memberId, consultSpecialtyName, stateCode, countryCode, consultationId } = $rootNode
      .find('#consult_form')
      .data();
    const { labsPrescribe, labOffers } = document.getElementById('lab_order_prescription').dataset;
    const labPrescriptionProps = {
      consultationId,
      memberId,
      consultSpecialtyName,
      stateCode,
      countryCode,
      preselectedData: {
        cachedLabsPrescribe: labsPrescribe,
        cachedLabOffers:     labOffers
      }
    };
    ReactDOM.render(
      <ApolloProvider client={getClientInstance()}>
        <LabPrescription {...labPrescriptionProps} />
      </ApolloProvider>,
      window.document.querySelector(`${rootSelector} #lab_order_prescription`)
    );
  }

  store.subscribe(() => notificationsListener(store.getState().notifications.current));

  initializeFavIconManager();

  render(Alert, `${rootSelector} #react_alert_popup`, {});

  const showCanceledVideoConsultPopup = $rootNode.find('#react_canceled_video_consult').data('showPopup');

  if (showCanceledVideoConsultPopup) {
    store.dispatch(showAlert({ componentId: PROPOSE_PHONE_ONLY_MESSAGE_ID, buttonText: 'Cancel' }));
  }

  providerActiveStatus.main();

  if (document.getElementById('react_consultation_queue')) {
    store.dispatch(initConsultationQueueRefresh());
  }

  if (document.getElementById('react_provider_dataset')) {
    const providerData = $('#react_provider_dataset').data();

    store.dispatch(updateProviderParams(providerData));
  }

  if (document.getElementById('react_provider_inactivity_tracker')) {
    const { shouldTrackForClockOut, clockOutAlertTiming } = $('#react_provider_inactivity_tracker').data();

    if (shouldTrackForClockOut) {
      store.dispatch(trackForClockOut(clockOutAlertTiming));
    }
  }

  if (document.getElementById('react_consult_inactivity_tracker')) {
    const consultInactivitySettings = $('#react_consult_inactivity_tracker').data();

    store.dispatch(trackConsultInactivity(consultInactivitySettings));
  }

  if (document.getElementById('react_provider_clock_in_out')) {
    const { combineVideoPhoneClockInButton } = $('#react_provider_clock_in_out').data();

    store.dispatch(setClockInOutSettings({ combineVideoPhoneClockInButton }));

    render(ProviderClockInOut, `${rootSelector} #react_provider_clock_in_out`);
  }

  const submitConsultBtn = document.getElementById('submit_consult_btn');
  if (submitConsultBtn) {
    const dataset = JSON.parse(submitConsultBtn.dataset.json);
    const message = dataset.message;
    const dismissSteps = dataset.dismiss_steps;
    const show = dataset.show;
    const shouldAskLocation = dataset.should_ask_location;
    const consultationId = dataset.consultation_id;
    ReactDOM.render(
      <ApolloProviderNew client={getClientInstance()}>
        <Provider store={store}>
          <SubmitConsult
            dataMessage={message}
            dataDismissSteps={dismissSteps}
            dataShow={show}
            shouldAskLocation={shouldAskLocation}
            consultationId={consultationId}
          />
        </Provider>
      </ApolloProviderNew>,
      submitConsultBtn
    );
  }

  const availabilityCheckSelector = document.getElementById('availability_check_modal');
  if (availabilityCheckSelector) {
    const providerId = rootNode.getAttribute('data-provider-id');
    ReactDOM.render(
      <ApolloProviderNew client={getClientInstance()}>
        <Provider store={store}>
          <AvailabilityCheckContainer providerId={parseInt(providerId)} />
        </Provider>
      </ApolloProviderNew>,
      availabilityCheckSelector
    );
  }

  if (document.getElementById('react_quick_links')) {
    // TODO refactor this so that quick links is connected to the redux store instead of using jquery twice to get this
    const providerData = $('#react_provider_dataset').data();
    render(QuickLinks, `${rootSelector} #react_quick_links`, providerData);
  }

  if (document.getElementById('react_consult_history_page_container')) {
    ReactDOM.render(
      <ApolloProviderNew client={sharedClientInstance}>
        <ConsultHistoryPageContainer />
      </ApolloProviderNew>,
      document.querySelector(`${rootSelector} #react_consult_history_page_container`)
    );
  }

  if (document.getElementById('provider-consultation-timer')) {
    const { configApiData } = $('#provider-consultation-timer').data();

    ReactDOM.render(
      <ApolloProviderNew client={sharedClientInstance}>
        <ProviderConsultationTimer consultation_id={configApiData} />
      </ApolloProviderNew>,
      document.querySelector(`${rootSelector} #provider-consultation-timer`)
    );
  }

  if (document.getElementById('provider-add-addendum')) {
    const { consultationData, showallactionsData, mentalStatusExamData, psychosocialStressorsData } = $(
      '#provider-add-addendum'
    ).data();
    ReactDOM.render(
      <AddAddendum
        consultation={consultationData}
        showActions={showallactionsData}
        mentalStatusExamData={mentalStatusExamData}
        psychosocialStressors={psychosocialStressorsData}
      />,
      document.querySelector(`${rootSelector} #provider-add-addendum`)
    );
  }

  if (document.getElementById('provider-actions')) {
    const {
      consultationData,
      showallactionsData,
      mentalStatusExamData,
      psychosocialStressorsData,
      canAddFollowupData
    } = $('#provider-actions').data();
    ReactDOM.render(
      <AddAddendum
        consultation={consultationData}
        showActions={showallactionsData}
        mentalStatusExamData={mentalStatusExamData}
        psychosocialStressors={psychosocialStressorsData}
        canAddFollowup={canAddFollowupData}
      />,
      document.querySelector(`${rootSelector} #provider-actions`)
    );
  }

  if (document.getElementById('react_network_test')) {
    const { shouldPerformTest } = $('#react_network_test').data();

    store.dispatch(updateNetworkTestSettings({ performTest: shouldPerformTest }));

    render(NetworkTest, `${rootSelector} #react_network_test`);
  }

  if (document.getElementById('react_inactivity_notifications')) {
    const { notifications } = $('#react_inactivity_notifications').data();

    render(InactivityNotifications, `${rootSelector} #react_inactivity_notifications`, {
      notifications
    });
  }

  if (document.querySelector(`${rootSelector} #react_antibiotic_study_solicitation`)) {
    const { providerId } = $('#react_antibiotic_study_solicitation').data();

    ReactDOM.render(
      <ApolloProviderNew client={getClientInstance()}>
        <ProviderSolicitation providerID={providerId} />
      </ApolloProviderNew>,
      document.querySelector(`${rootSelector} #react_antibiotic_study_solicitation`)
    );
  }

  if (document.querySelector(`${rootSelector} #react_antibiotic_study_prompts`)) {
    const { providerId } = $('#react_antibiotic_study_prompts').data();

    ReactDOM.render(
      <ApolloProviderNew client={getClientInstance()}>
        <ProviderPrompts providerID={providerId} />
      </ApolloProviderNew>,
      document.querySelector(`${rootSelector} #react_antibiotic_study_prompts`)
    );
  }
  if (document.getElementById('nps')) {
    ReactDOM.render(
      <ApolloProviderNew client={getClientInstance()}>
        <NetPromoterScore />
      </ApolloProviderNew>,
      document.getElementById('nps')
    );
  }

  const myScheduleSelector = document.getElementById('react_calendar');

  if (myScheduleSelector) {
    const {
      providerId,
      providerServiceSpecialties,
      schedulingTimeBuffer,
      providerCalendarV2,
      recommendWorkHourFlg,
      canEditSchedule
    } = $('#react_calendar').data();
    const {
      enabled: dedicatedAppointmentsBlocksIsEnabled,
      data: { specialties: dedicatedAppointmentsSpecialties }
    } = window.FEATURE_TOGGLES_DATA.dedicated_appt_blocks;
    const serviceSpecialtyEnabled = keys(dedicatedAppointmentsSpecialties).some(specialty =>
      providerServiceSpecialties.includes(specialty)
    );

    ReactDOM.render(
      <ApolloProviderNew client={sharedClientInstance}>
        <RecommendedWorkingHoursProvider
          providerId={providerId}
          providerSpecialties={providerServiceSpecialties}
          providerSchedulingTimeBuffer={schedulingTimeBuffer}
          recommendWorkHourFlg={recommendWorkHourFlg || !providerCalendarV2}
          canEditSchedule={canEditSchedule}
        >
          {providerCalendarV2 ? (
            <MyScheduleContainer
              providerId={providerId}
              apolloClient={sharedClientInstance}
              canEditSchedule={canEditSchedule}
            />
          ) : (
            <ProviderCalendar
              providerId={providerId}
              apolloClient={sharedClientInstance}
              dedicatedAppointments={dedicatedAppointmentsBlocksIsEnabled && serviceSpecialtyEnabled}
              canEditSchedule={canEditSchedule}
              providerSpecialties={providerServiceSpecialties}
            />
          )}
        </RecommendedWorkingHoursProvider>
      </ApolloProviderNew>,
      myScheduleSelector
    );
  }

  if (document.getElementById('availability_notification')) {
    const isOpen = $rootNode.find('#availability_notification').data('isopen');

    render(AvailabilityNotification, `${rootSelector} #availability_notification`, { isOpen });
  }

  if (document.getElementById('availability_reminder')) {
    render(AvailabilityReminder, `${rootSelector} #availability_reminder`);
  }

  initializeConsultFollowup(rootSelector, sharedClientInstance);
  initializeInfoboxSpecialties(rootSelector, sharedClientInstance);
  initializeConsultFollowupTitle(rootSelector, sharedClientInstance);

  const reactCarePlanShowElement = document.getElementById('react_care_plan_show');
  if (reactCarePlanShowElement) {
    render(CarePlanShow, `${rootSelector} #react_care_plan_show`, reactCarePlanShowElement.dataset);
  }

  if (document.getElementById('rescheduling_modal')) {
    ReactDOM.render(
      <ConsultationReschedulingModal
        onClose={props.modal.close}
        consultationId={props.consultationId}
        requestedDates={props.requestedDates}
        memberId={props.memberId}
        providerId={props.providerId}
        isWebsdkRescheduleToggle={props.isWebsdkRescheduleToggle}
        isTimeSlotOnly={props.isTimeSlotOnly}
        reschedulingIntervals={props.reschedulingIntervals}
        intervalBetweenScheduling={props.intervalBetweenScheduling}
      />,
      document.querySelector('#rescheduling_modal .rescheduling_modal_content')
    );
  }

  const renderEhr = (containerId, extraProps = {}) => {
    const ehrManager = document.querySelector(`#${containerId} .ehr_section_manager`);

    if (ehrManager) {
      if (ehrManager.dataset.usebarloader && !extraProps.hasOwnProperty('barLoader')) {
        extraProps.barLoader = true;
      }

      const props = {
        memberId:       parseInt(ehrManager.dataset.memberId),
        consultationId: parseInt(ehrManager.dataset.consultationId),
        activeConsult:  !!ehrManager.dataset.activeConsult,
        containerId,
        ...extraProps
      };

      if (isUndefined(props.enableMhSpecificDocumentsNotes)) {
        props.enableMhSpecificDocumentsNotes = false;
      }

      ReactDOM.render(
        <ApolloProviderNew client={sharedClientInstance}>
          <Provider store={store}>
            <EHR {...props} />
          </Provider>
        </ApolloProviderNew>,
        ehrManager
      );
    }
  };

  renderEhr('consultation_ehr_he', {
    reviewCompleted:                   wasEhrTabReviewedBefore(),
    editable:                          true,
    enableMhSpecificDocumentsNotes:    !wasEhrTabReviewedBefore(),
    enableDisplayOfReviewRequiredText: false
  });

  // Note: consultation_ehr element is used on both EHR review and QA page
  renderEhr('consultation_ehr', {
    reviewCompleted:                   wasEhrTabReviewedBefore(),
    editable:                          true,
    enableMhSpecificDocumentsNotes:    !wasEhrTabReviewedBefore(),
    enableDisplayOfReviewRequiredText: false
  });

  renderEhr('patient_ehr_preview_modal', { expandFirstSection: true });
  renderEhr('ehr_highlights', { fullDetails: false, editable: true });
  renderEhr('consultation_history_ehr', { editable: true });

  const multiWayConsultAttendeesVerificationSelector = `${rootSelector} #react_multi_way_consult_attendees_verification_container`;

  if ($(multiWayConsultAttendeesVerificationSelector).length) {
    const consultAttendees = $(`${rootSelector} #react_multi_way_consult_attendees_verification_container`).data(
      'consult-attendees'
    );
    const preSelectedConsultAttendees = $(`${rootSelector} #react_multi_way_consult_attendees_verification_container`)
      .data('preselected-consult-attendees')
      .map(attendee => +attendee.id);

    render(MultiWayConsultAttendeesVerification, multiWayConsultAttendeesVerificationSelector, {
      consultAttendees,
      preSelectedConsultAttendees
    });
  }

  const mentalStatusExamSelector = `${rootSelector} #react_mental_status_exam_container`;

  if ($(mentalStatusExamSelector).length) {
    const mentalStatusExamSurvey = camelizeDeep($(mentalStatusExamSelector).data('mental-status-exam-survey'));
    const cachedAnswers = $(mentalStatusExamSelector).data('mental-health-cached-survey');
    render(MentalStatusExam, mentalStatusExamSelector, { mentalStatusExamSurvey, cachedAnswers });
    // wrap survey in EhrToggler to achieve the dropdown section design
    new EhrToggler($('.mental-status-exam div.ct'), null, false).toggle();
  }

  const psychosocialStressorSelector = `${rootSelector} #react_psychosocial_stressors_container`;

  if ($(psychosocialStressorSelector).length) {
    const psychosocialStressorsSurvey = camelizeDeep(
      $(psychosocialStressorSelector).data('psychosocial-stressors-survey')
    );
    const cachedAnswers = $(psychosocialStressorSelector).data('psychosocial-cached-survey');
    render(PsychosocialStressors, psychosocialStressorSelector, { psychosocialStressorsSurvey, cachedAnswers });
  }

  if (document.getElementById('react_dashboard_content')) {
    // patient_volume_configuration v2
    const state = store.getState();
    const clockInType = get(getProviderParams(state), ['clockInType']);
    const clockedIn = getProviderClockedIn(state);

    ReactDOM.render(
      <ApolloProviderNew client={sharedClientInstance}>
        <DataProvider value={{ clockInType, clockedIn, ...$('#react_dashboard_content').data() }}>
          <DashboardContent />
        </DataProvider>
      </ApolloProviderNew>,
      document.querySelector('#react_dashboard_content')
    );
  }

};
