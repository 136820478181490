import React from 'react';
import PropTypes from 'prop-types';

const SystemGeneratedNote = ({ generalInformationNote, symptomsNote }) => (
  <div>
    <h3>System-Generated Note</h3>
    <div className="system-generated-note">
      <div id="general-information-note">{generalInformationNote}</div>
      <div id="symptoms-note">{symptomsNote}</div>
      <input
        type="hidden"
        value={`${generalInformationNote}\n${symptomsNote}`}
        name="interview[clinical_notes[0]][note]"
        data-name="subjective_note"
      />
      <input
        type="hidden"
        value="NOTETYPE_SUB"
        name="interview[clinical_notes[0]][note_type_cd]"
        data-name="subjective_note_type"
      />
    </div>
  </div>
);

SystemGeneratedNote.propTypes = {
  generalInformationNote: PropTypes.string,
  symptomsNote:           PropTypes.string
};

export default SystemGeneratedNote;
